import React, { useState } from "react";
import WbIncandescentTwoToneIcon from "@mui/icons-material/WbIncandescentTwoTone";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/List";
import { Link } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Cart from "../cart/Cart";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "./navbar.css";

export const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const products = useSelector((state) => state.cart.products);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setIsCartOpen(false);
  };

  const toggleShop = () => {
    setMenuOpen(false);
    setIsCartOpen(!isCartOpen);
    setMenuOpen(false);
  };

  return (
    <div className="navbarWrapper">
      {" "}
      <div className="dropdown-menu-container phone-only">
        <Link className="link" to="/">
          <div className="titleWrap">
            <h1>GAZAR</h1>
            <h2>GROUP</h2>
          </div>
        </Link>
        <div className="menu-items">
          <div className="cartIconWrap">
            <ShoppingCartOutlinedIcon
              onClick={() => setIsCartOpen(!isCartOpen)}
              className="cartIcon"
            />
            <span>{products.length}</span>
          </div>
          {isCartOpen && <Cart />}
          <div className="menuWrap">
            <MenuIcon className="menuWrapIcon" onClick={toggleMenu} />
          </div>
        </div>

        {isMenuOpen && !isCartOpen && (
          <div className="dropdown-menu-opened" data-aos="fade-left">
            {/* Your dropdown menu items go here */}
            <Link className="link" to="/aboutus">
              <div className="headerItem">
                <span>O nás</span>
              </div>
            </Link>
            <Link className="link" to="/">
              <div className="headerItem">
                <span>Náš sortiment</span>
              </div>
            </Link>
            <Link className="link" to="/termsandconditions">
              <div className="headerItem">
                <span>Obchodní podmínky</span>
              </div>
            </Link>
            <Link className="link" to="/shopguide">
              <div className="headerItem">
                <span>Jak nakupovat</span>
              </div>
            </Link>
          </div>
        )}
      </div>
      <div className="navbar-menu desktop-only">
        <div className="menuCenter">
          <Link className="link" to="/">
            <div className="titleWrap">
              <h1>GAZAR</h1>
              <h2>GROUP</h2>
            </div>
          </Link>
          <header>
            <Link className="link" to="/aboutus">
              <span>O nás</span>
            </Link>
            <Link className="link" to="/">
              <span>Náš sortiment</span>
            </Link>
            <Link className="link" to="/termsandconditions">
              <span>Obchodní podmínky</span>
            </Link>
            <Link className="link" to="/shopguide">
              <span>Jak nakupovat</span>
            </Link>
            <div className="cartIconWrap" onClick={toggleShop}>
              <ShoppingCartOutlinedIcon className="cartIcon" />
              <span>{products.length}</span>
            </div>{" "}
          </header>
          {isCartOpen && <Cart />}
        </div>
      </div>
    </div>
  );
};
