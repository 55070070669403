import React from "react";
import "./infoPanel.css";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

export const InfoPanel = () => {
  return (
    <div
      className="infoPanelWrapper"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <div className="columnsWrapper">
        <div className="column">
          <div className="iconWrap gradientOutline">
            <HandshakeIcon className="icon" />
          </div>
          <span>Jsme zárukou spolehlivého obchodu již přes 25 let.</span>
        </div>
        <div className="column">
          <div className="iconWrap gradientOutline">
            <PhoneIcon className="icon" />
          </div>
          <span>Zavolejte nám na číslo 720 978 703, jsme tu pro Vás.</span>
        </div>
        <div className="column">
          <div className="iconWrap gradientOutline">
            <EmailIcon className="icon" />
          </div>
          <span>
            Nenašli jste co hledáte? Napište nám na info@gazargroup.com
          </span>
        </div>
      </div>
    </div>
  );
};
