import React from "react";
import { Navbar } from "../../components/navbar/Navbar";
import { HowToOrderInfo } from "../../components/howToOrderInfo/HowToOrderInfo";
import Footer from "../../components/footer/Footer";
import "./howToOrder.scss";
import { ContactForm } from "../../components/contactForm/ContactForm";
import { GoBackButton } from "../../components/buttons/Buttons";
import { Helmet } from "react-helmet";

export const HowToOrder = () => {
  return (
    <div className="howToOrderWrap parentDiv">
      <Helmet>
        <title>Jak u nás nakupovat | Gazar Group</title>
        <meta
          name="description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          name="keywords"
          content="VÝBĚR ZBOŽÍ, PŘIDÁNÍ DO KOŠÍKU, POTVRZENÍ KOŠÍKU, VYPLŇTE FORMULÁŘ"
        />
        <meta property="og:title" content="Jak u nás nakupovat | Gazar Group" />
        <meta
          property="og:description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          property="og:image"
          content="https://gazargroup.com/logo192.png"
        />
        <meta property="og:url" content={`https://gazargroup.com/shopguide`} />
        <meta
          name="twitter:title"
          content={`Jak u nás nakupovat | Gazar Group`}
        />
        <meta
          name="twitter:description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          name="twitter:image"
          content="https://gazargroup.com/logo192.png"
        />
      </Helmet>
      <div className="pageWrapper">
        <nav>
          <Navbar />
        </nav>
        <div className="bodyContent">
          <GoBackButton />
          <HowToOrderInfo data-aos="zoom-in" data-aos-duration="1000" />
          <ContactForm isPopUp={true} />
          <Footer />
        </div>
      </div>
    </div>
  );
};
