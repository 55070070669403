import React from "react";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import "./footer.css"; // Replace 'YourComponent' with your component name
import { LocationOn } from "@mui/icons-material";

const Footer = () => {
  return (
    <div className="footer">
      <div className="imageWrapper desktop-only">
        <img
          className="productImage"
          src={require(`../../media/gazargrouplogo.png`)}
          alt=""
        />
      </div>
      <div className="middleSection">
        <div className="company-data" id="contact">
          <div className="company-name"> Gazar Group s.r.o. </div>
          <div className="row">
            <div className="iconWrap gradientOutline">
              <LocationOnIcon className="icon" />
            </div>
            <span>
              <strong>ADRESA:</strong> Liškova 1770/64, Předměstí, 412 01
              Litoměřice
            </span>
          </div>
          <div className="row">
            <div className="iconWrap gradientOutline">
              <MailIcon className="icon" />
            </div>
            <span>
              <strong>MAIL:</strong> info@gazargroup.com
            </span>
          </div>
          <div className="row">
            <div className="iconWrap gradientOutline">
              <PhoneIcon className="icon" />
            </div>
            <span>
              <strong>TELEFON:</strong> +420 720 978 703
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
