// FinishOrder.jsx
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import { InsertEmoticon, SentimentDissatisfied } from "@mui/icons-material";

import "./finishOrder.scss";
import { Navbar } from "../../components/navbar/Navbar";
import ProductsTable from "../../components/productsTable/ProductsTable";
import { ContactForm } from "../../components/contactForm/ContactForm";
import Footer from "../../components/footer/Footer";
import FinishOrderForm from "../../components/finishOrderForm/FinishOrderForm";
import { GoBackButton } from "../../components/buttons/Buttons";
import FinishOrderInfo from "../../components/finishOrderInfo/FinishOrderInfo";
import { Helmet } from "react-helmet";

const FinishOrder = () => {
  return (
    <div className="parentDiv">
      <Helmet>
        <title>Dokončit poptávku | Gazar Group</title>
        <meta name="description" content="Shrnutí Vaší poptávky" />
        <meta
          name="keywords"
          content="Dodáváme světelné zdroje do odvětví Zdravotnictví, Optiky, Laboratoří, Průmyslu a speciálních aplikací. Optika, (Optické halogenové žárovky), Průmysl (Signální a kontrolky, Výbojky, Vysokotlaké výbojky, Zářivky UV BLB, Speciální žárovky, Projekční žárovky, HBO - rtuťové výbojky), Ostatní odvětví (Zářivky T5 patice G5, Kompaktní kolíkové, Žářivky T8 patice G13, Žárovky patice E27, Telefonní žárovky Neváhejte nás kontaktovat pokud v naší nabídce nenajdete co hledáte. Náš sortiment neustále rozšiřujeme. "
        />
        <meta property="og:title" content="Dokončit poptávku | Gazar Group" />
        <meta
          property="og:description"
          content="Dodáváme světelné zdroje do odvětví Zdravotnictví, Optiky, Laboratoří, Průmyslu a speciálních aplikací. Optika, (Optické halogenové žárovky), Průmysl (Signální a kontrolky, Výbojky, Vysokotlaké výbojky, Zářivky UV BLB, Speciální žárovky, Projekční žárovky, HBO - rtuťové výbojky), Ostatní odvětví (Zářivky T5 patice G5, Kompaktní kolíkové, Žářivky T8 patice G13, Žárovky patice E27, Telefonní žárovky Neváhejte nás kontaktovat pokud v naší nabídce nenajdete co hledáte. Náš sortiment neustále rozšiřujeme. "
        />
        <meta
          property="og:image"
          content="https://gazargroup.com/logo192.png"
        />
        <meta property="og:url" content={`https://gazargroup.com/checkout`} />
        <meta
          name="twitter:title"
          content={`Dokončit poptávku | Gazar Group`}
        />
        <meta
          name="twitter:description"
          content="Dodáváme světelné zdroje do odvětví Zdravotnictví, Optiky, Laboratoří, Průmyslu a speciálních aplikací. Optika, (Optické halogenové žárovky), Průmysl (Signální a kontrolky, Výbojky, Vysokotlaké výbojky, Zářivky UV BLB, Speciální žárovky, Projekční žárovky, HBO - rtuťové výbojky), Ostatní odvětví (Zářivky T5 patice G5, Kompaktní kolíkové, Žářivky T8 patice G13, Žárovky patice E27, Telefonní žárovky Neváhejte nás kontaktovat pokud v naší nabídce nenajdete co hledáte. Náš sortiment neustále rozšiřujeme. "
        />
        <meta
          name="twitter:image"
          content="https://gazargroup.com/logo192.png"
        />
      </Helmet>
      <div className="pageWrapper">
        <nav>
          <Navbar />
        </nav>
        <div className="bodyContent">
          <FinishOrderInfo />
          <ContactForm isPopUp={true} />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FinishOrder;
