import React from "react";
import "./termsAndConditions.scss";
import { Navbar } from "../../components/navbar/Navbar";
import { TermsAndConditionsInfo } from "../../components/termsAndConditionsInfo/TermsAndConditionsInfo";
import Footer from "./../../components/footer/Footer";
import { GoBackButton } from "../../components/buttons/Buttons";
import { ContactForm } from "../../components/contactForm/ContactForm";
import { Helmet } from "react-helmet";

export const TermsAndConditions = () => {
  return (
    <div className="termsAndConditionsWrapper parentDiv">
      <Helmet>
        <title>Obchodní podmínky | Gazar Group</title>
        <meta
          name="description"
          content="Kupující učiněním závazné objednávky stvrzuje, že akceptuje Obchodní podmínky pro dodávku zboží vyhlášené prodávajícím. Vztahy mezi kupujícím a prodávajícím se řídí těmito obchodními podmínkami, které jsou zároveň pro obě strany závazné."
        />
        <meta
          name="keywords"
          content="VŠEOBECNÁ USTANOVENÍ, OBJEDNÁNÍ ZBOŽÍ A SLUŽEB, POTVRZENÍ KOŠÍKU, VYPLŇTE FORMULÁŘ "
        />
        <meta property="og:title" content="Jak u nás nakupovat | Gazar Group" />
        <meta
          property="og:description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          property="og:image"
          content="https://gazargroup.com/logo192.png"
        />
        <meta property="og:url" content={`https://gazargroup.com/shopguide`} />
        <meta
          name="twitter:title"
          content={`Jak u nás nakupovat | Gazar Group`}
        />
        <meta
          name="twitter:description"
          content="Naše stránka funguje primárně jako portfolio produktů, které můžete
            poptat. Vámi poptané zboží zpracujeme do cenové nabídky a dohodneme
            platební metodu."
        />
        <meta
          name="twitter:image"
          content="https://gazargroup.com/logo192.png"
        />
      </Helmet>
      <div className="pageWrapper">
        <nav>
          <Navbar />
        </nav>
        <div className="bodyContent">
          <GoBackButton className="desktop-only" />
          <TermsAndConditionsInfo />
          <ContactForm isPopUp={true} />
          <Footer />
        </div>
      </div>
    </div>
  );
};
