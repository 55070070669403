import React from "react";
import "./home.css";
import { Hero } from "../../components/hero/Hero";
import { Navbar } from "../../components/navbar/Navbar";
import { ContactForm } from "../../components/contactForm/ContactForm";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <div className="home parentDiv">
        <Helmet>
          <title>O nás | Gazar Group</title>
          <meta
            name="description"
            content="Dodáváme světelné zdroje výrobců:
            Angenieux, Apollon, Bailey Electric, Baro, Baush&Lomb, BBT, Berchtold, BLV, Dr. Fischer Dr. Mach, EYE, General Eletric, Gowlland, Guerra, Hanaulux, Heine, Heraeus, Hitachi, Iwasaki, Keeler, Leuci, Lohuis, Martin, Mc Intosch, Narva, Olympus, Orbitec, ORC, Oshino lamp, Osram, Panasonic, Pentax, PerkinElmer, Philips, Radium, Sidel, Skylux, SLI, Storz, Sylvania, Tesla, Teslamp, Toschiba, Ushio, Venture Lighting, Welch Allyn, Wild, Wolf, Zeiss a další."
          />
          <meta
            name="keywords"
            content="Angenieux, Apollon, Bailey Electric, Baro, Baush&Lomb, BBT, Berchtold, BLV, Dr. Fischer Dr. Mach, EYE, General Eletric, Gowlland, Guerra, Hanaulux, Heine, Heraeus, Hitachi, Iwasaki, Keeler, Leuci, Lohuis, Martin, Mc Intosch, Narva, Olympus, Orbitec, ORC, Oshino lamp, Osram, Panasonic, Pentax, PerkinElmer, Philips, Radium, Sidel, Skylux, SLI, Storz, Sylvania, Tesla, Teslamp, Toschiba, Ushio, Venture Lighting, Welch Allyn, Wild, Wolf, Zeiss a další"
          />
          <meta property="og:title" content="O nás | Gazar Group" />
          <meta
            property="og:description"
            content="Dodáváme světelné zdroje výrobců:
            Angenieux, Apollon, Bailey Electric, Baro, Baush&Lomb, BBT, Berchtold, BLV, Dr. Fischer Dr. Mach, EYE, General Eletric, Gowlland, Guerra, Hanaulux, Heine, Heraeus, Hitachi, Iwasaki, Keeler, Leuci, Lohuis, Martin, Mc Intosch, Narva, Olympus, Orbitec, ORC, Oshino lamp, Osram, Panasonic, Pentax, PerkinElmer, Philips, Radium, Sidel, Skylux, SLI, Storz, Sylvania, Tesla, Teslamp, Toschiba, Ushio, Venture Lighting, Welch Allyn, Wild, Wolf, Zeiss a další."
          />
          <meta
            property="og:image"
            content="https://gazargroup.com/logo192.png"
          />
          <meta property="og:url" content={`https://gazargroup.com/aboutus`} />
          <meta name="twitter:title" content={`O nás | Gazar Group`} />
          <meta
            name="twitter:description"
            content="Dodáváme světelné zdroje výrobců:
            Angenieux, Apollon, Bailey Electric, Baro, Baush&Lomb, BBT, Berchtold, BLV, Dr. Fischer Dr. Mach, EYE, General Eletric, Gowlland, Guerra, Hanaulux, Heine, Heraeus, Hitachi, Iwasaki, Keeler, Leuci, Lohuis, Martin, Mc Intosch, Narva, Olympus, Orbitec, ORC, Oshino lamp, Osram, Panasonic, Pentax, PerkinElmer, Philips, Radium, Sidel, Skylux, SLI, Storz, Sylvania, Tesla, Teslamp, Toschiba, Ushio, Venture Lighting, Welch Allyn, Wild, Wolf, Zeiss a další."
          />
          <meta
            name="twitter:image"
            content="https://gazargroup.com/logo192.png"
          />
        </Helmet>
        <div className="pageWrapper">
          <nav>
            <Navbar />
          </nav>
          <div className="bodyContent">
            <Hero />
            <ContactForm isPopUp={true} />
          </div>
        </div>
      </div>
    </>
  );
}
