import React, { useEffect, useState } from "react";
import "./productItem.css";
import { descriptions } from "../../ps_descriptions";
import { categories } from "../../ps_category";

//TODO: Navbar dát position fixed, Footer dát gradient a snížit písmo, dodělat zbytek stránek

export const ProductItem = ({ product }) => {
  const foundProductDesc = descriptions.find(
    (description) => description.id_product === product.id_product
  );

  const foundProductCategory = categories.find(
    (category) => category.id_category === product.id_category_default
  );

  return (
    <div className="product-cell ">
      <div className="product-img">
        <img
          src={require(`../../media/productImages/${product.id_product}.jpg`)}
          alt={foundProductDesc.name}
          className=""
        />
      </div>
      <div className="product-info ">
        <div className="product-name ">{foundProductDesc.name}</div>
        <div className="product-category gradientOutline">
          {foundProductCategory.name}
        </div>
      </div>
    </div>
  );
};
