import React, { useEffect, useState } from "react";
import "./products.css";
import { Navbar } from "../../components/navbar/Navbar";
import ProductList from "../../components/productsList/ProductsList";
import { ContactForm } from "./../../components/contactForm/ContactForm";
import { AutoSlider } from "../../components/autoSlider/AutoSlider";
import { InfoPanel } from "../../components/infoPanel/InfoPanel";
import { CarouselHorizontal } from "../../components/carouselHorizontal/CarouselHorizontal";
import Footer from "../../components/footer/Footer";
import { products } from "../../ps_products";
import { manufacturers } from "./../../ps_manufacturer";
import { categories } from "../../ps_category";
import { descriptions } from "../../ps_descriptions";
import { ProductItem } from "../../components/productItem/ProductItem";
import { useLocation } from "react-router-dom";
import { resetCart } from "../../redux/cartReducer";
import { PopUpFinish } from "../../components/popUpFinish/PopUpFinish";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

export const Products = () => {
  const orderIsFinised = "?orderFinished" === useLocation().search;
  const dispatch = useDispatch();
  orderIsFinised && dispatch(resetCart());

  return (
    <div
      className={
        orderIsFinised ? "productsWrap no-scroll" : "productsWrap scroll"
      }
    >
      <Helmet>
        <title>Náš sortiment žárovek | Gazar Group</title>
        <meta
          name="description"
          content="Dodáváme světelné zdroje do odvětví Zdravotnictví, Optiky, Laboratoří, Průmyslu a speciálních aplikací. Optika, (Optické halogenové žárovky), Průmysl (Signální a kontrolky, Výbojky, Vysokotlaké výbojky, Zářivky UV BLB, Speciální žárovky, Projekční žárovky, HBO - rtuťové výbojky), Ostatní odvětví (Zářivky T5 patice G5, Kompaktní kolíkové, Žářivky T8 patice G13, Žárovky patice E27, Telefonní žárovky Neváhejte nás kontaktovat pokud v naší nabídce nenajdete co hledáte. Náš sortiment neustále rozšiřujeme. "
        />
        <meta
          name="keywords"
          content="Optika, (Optické halogenové žárovky), Průmysl (Signální a kontrolky, Výbojky, Vysokotlaké výbojky, Zářivky UV BLB, Speciální žárovky, Projekční žárovky, HBO - rtuťové výbojky), Ostatní odvětví (Zářivky T5 patice G5, Kompaktní kolíkové, Žářivky T8 patice G13, Žárovky patice E27, Telefonní žárovky"
        />
        <meta
          property="og:title"
          content="Žárovky pro Vás přes 25 let | Gazar Group"
        />
        <meta
          property="og:description"
          content="Dodáváme světelné zdroje do odvětví Zdravotnictví, Optiky, Laboratoří, Průmyslu a speciálních aplikací. Neváhejte nás kontaktovat pokud v naší nabídce nenajdete co hledáte. Náš sortiment neustále rozšiřujeme. "
        />
        <meta
          property="og:image"
          content="https://gazargroup.com/logo192.png"
        />
        <meta property="og:url" content={`https://gazargroup.com`} />
        <meta
          name="twitter:title"
          content="Žárovky pro Vás přes 25 let | Gazar Group"
        />
        <meta
          name="twitter:description"
          content="Dodáváme světelné zdroje do odvětví Zdravotnictví, Optiky, Laboratoří, Průmyslu a speciálních aplikací. Neváhejte nás kontaktovat pokud v naší nabídce nenajdete co hledáte. Náš sortiment neustále rozšiřujeme. "
        />
        <meta
          name="twitter:image"
          content="https://gazargroup.com/logo192.png"
        />
      </Helmet>
      <div className="productsWrap2 parentDiv">
        {orderIsFinised && <PopUpFinish />}
        <>
          <div className="pageWrapper">
            <nav>
              <Navbar />
            </nav>
            <div className="bodyContent">
              <AutoSlider />
              <InfoPanel />
              <CarouselHorizontal />
              <ProductList />
              <ContactForm isPopUp={true} />
              <Footer />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
