import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./hero.css";

export const Hero = () => {
  return (
    <div className="heroWrap" data-aos="zoom-in" data-aos-duration="1000">
      <video className="videoElement" loop autoPlay muted playsInline>
        <source
          src={require(`../../media/videos/vlaknoZarovky.mp4`)}
          type="video/mp4"
        />
      </video>

      <div className="heroTextWrap">
        <div className="heroAboutWrapTitle">
          <h1>GAZAR</h1>
          <h2>GROUP</h2>
        </div>

        <div className="heroAboutWrapText">
          <span>
            <b>Dodáváme světelné zdroje výrobců:</b> <br />
            <i>
              Angenieux, Apollon, Bailey Electric, Baro, Baush&Lomb, BBT,
              Berchtold, BLV, Dr. Fischer Dr. Mach, EYE, General Eletric,
              Gowlland, Guerra, Hanaulux, Heine, Heraeus, Hitachi, Iwasaki,
              Keeler, Leuci, Lohuis, Martin, Mc Intosch, Narva, Olympus,
              Orbitec, ORC, Oshino lamp, Osram, Panasonic, Pentax, PerkinElmer,
              Philips, Radium, Sidel, Skylux, SLI, Storz, Sylvania, Tesla,
              Teslamp, Toschiba, Ushio, Venture Lighting, Welch Allyn, Wild,
              Wolf, Zeiss a další.{" "}
            </i>
          </span>
          <br /> <br />
          <span>
            Objednávky nebo poptávky i na typy světelných zdrojů neuvedených v
            našem internetovém obchodě lze uskutečnit i telefonicky na{" "}
            <b>720 978 703</b> nebo email:<b>info@gazargroup.com</b>
          </span>
          <br /> <br />
          <span>
            <b>
              Jestliže potřebujete jakoukoliv žárovku, kontaktujte nás, rádi Vám
              poradíme a Vy ušetříte čas.
            </b>{" "}
            Denně se vyrábějí nové typy žárovek a montují se zařízení s novým
            osvětlením. Dodáváme žárovky všech světových výrobců.
            <br />
            <br />
            Sídlo firmy se nachází na adrese{" "}
            <b> Liškova 1770/64, Předměstí, 412 01 Litoměřice</b>
            <br />
            <br />
            <br />
          </span>
          <div className="heroAboutLinks desktop-only">
            <div className="column">
              <div className="iconWrap gradientOutline">
                <LocationOnIcon className="icon" />
              </div>
              <span>
                ADRESA: Liškova 1770/64, Předměstí, 412 01 Litoměřice.
              </span>
            </div>
            <div className="column">
              <div className="iconWrap gradientOutline">
                <MailIcon className="icon" />
              </div>
              <span>MAIL: info@gazargroup.com</span>
            </div>
            <div className="column">
              <div className="iconWrap gradientOutline">
                <PhoneIcon className="icon" />
              </div>
              <span>TELEFON: +420 720 978 703</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
