 const products =[
    {
      "id_product": 971,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-17",
      "reduction_to": "2014-01-17",
      "reference": 55939,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-17 15:01:00",
      "date_upd": "2014-02-20 15:29:25"
    },
    {
      "id_product": 973,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790714053,
      "ecotax": 0,
      "quantity": 10,
      "price": 980,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-17",
      "reduction_to": "2014-01-17",
      "reference": "125344n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-17 15:39:39",
      "date_upd": "2023-05-10 12:41:52"
    },
    {
      "id_product": 974,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790669056,
      "ecotax": 0,
      "quantity": 1,
      "price": 895,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-20",
      "reduction_to": "2014-01-20",
      "reference": "055171",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-20 15:19:55",
      "date_upd": "2023-01-17 09:24:38"
    },
    {
      "id_product": 975,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 5,
      "price": 1200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "000003n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 09:08:20",
      "date_upd": "2020-11-13 13:32:37"
    },
    {
      "id_product": 976,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790716057,
      "ecotax": 0,
      "quantity": 100,
      "price": 520,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "00003n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 09:15:32",
      "date_upd": "2022-04-22 08:52:28"
    },
    {
      "id_product": 977,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790708052,
      "ecotax": 0,
      "quantity": 20,
      "price": 980,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": 125343,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 10:55:08",
      "date_upd": "2021-05-11 09:14:37"
    },
    {
      "id_product": 978,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 960,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "62261n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 10:58:47",
      "date_upd": "2020-11-23 10:29:41"
    },
    {
      "id_product": 979,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "67225n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 13:38:49",
      "date_upd": "2014-02-20 15:32:48"
    },
    {
      "id_product": 980,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 4,
      "price": 510,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "67612n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 13:46:02",
      "date_upd": "2017-11-30 10:32:59"
    },
    {
      "id_product": 981,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790509055,
      "ecotax": 0,
      "quantity": 0,
      "price": 470,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "125498n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 13:53:03",
      "date_upd": "2023-01-12 12:05:59"
    },
    {
      "id_product": 982,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "55128n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 13:57:58",
      "date_upd": "2014-02-20 15:30:17"
    },
    {
      "id_product": 983,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "55140n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 14:01:31",
      "date_upd": "2014-02-20 15:31:29"
    },
    {
      "id_product": 984,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790515056,
      "ecotax": 0,
      "quantity": 20,
      "price": 370,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": 125499,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 14:05:02",
      "date_upd": "2015-09-02 10:13:55"
    },
    {
      "id_product": 985,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790516053,
      "ecotax": 0,
      "quantity": 10,
      "price": 490,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "000004",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 14:08:25",
      "date_upd": "2020-11-13 13:32:02"
    },
    {
      "id_product": 986,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 850,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "55166n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 14:40:04",
      "date_upd": "2014-02-20 15:32:03"
    },
    {
      "id_product": 987,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 650,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-21",
      "reduction_to": "2014-01-21",
      "reference": "55168n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-21 14:42:36",
      "date_upd": "2014-02-20 15:32:24"
    },
    {
      "id_product": 988,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790518057,
      "ecotax": 0,
      "quantity": 5,
      "price": 540,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-22",
      "reduction_to": "2014-01-22",
      "reference": "125551n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-22 13:52:45",
      "date_upd": "2014-03-23 10:16:13"
    },
    {
      "id_product": 989,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790519054,
      "ecotax": 0,
      "quantity": 5,
      "price": 527,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-22",
      "reduction_to": "2014-01-22",
      "reference": "000306n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-22 13:55:58",
      "date_upd": "2015-09-02 10:16:44"
    },
    {
      "id_product": 990,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 4,
      "price": 595,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-22",
      "reduction_to": "2014-01-22",
      "reference": "125540n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-22 13:58:00",
      "date_upd": "2020-11-13 13:29:42"
    },
    {
      "id_product": 991,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 975,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-22",
      "reduction_to": "2014-01-22",
      "reference": "55176n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-22 14:00:22",
      "date_upd": "2014-02-20 15:33:10"
    },
    {
      "id_product": 992,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 950,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-22",
      "reduction_to": "2014-01-22",
      "reference": "55307n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-22 14:02:44",
      "date_upd": "2023-08-09 12:27:13"
    },
    {
      "id_product": 993,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-22",
      "reduction_to": "2014-01-22",
      "reference": "62252n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-22 14:26:15",
      "date_upd": "2019-09-02 08:31:48"
    },
    {
      "id_product": 994,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790722058,
      "ecotax": 0,
      "quantity": 5,
      "price": 1200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "125345n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 09:35:03",
      "date_upd": "2017-11-30 10:42:52"
    },
    {
      "id_product": 995,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 2,
      "price": 380,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "67461n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 09:41:47",
      "date_upd": "2017-11-30 10:46:09"
    },
    {
      "id_product": 996,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4013790710055,
      "ecotax": 0,
      "quantity": 0,
      "price": 1200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "000006n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 09:51:45",
      "date_upd": "2016-09-06 14:39:52"
    },
    {
      "id_product": 997,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 80,
      "price": 110,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "67171n",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:03:06",
      "date_upd": "2015-09-02 10:17:36"
    },
    {
      "id_product": 999,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 9800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "57055s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:30:44",
      "date_upd": "2014-02-20 15:36:38"
    },
    {
      "id_product": 1000,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 23,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "55125s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:34:57",
      "date_upd": "2017-11-30 10:37:02"
    },
    {
      "id_product": 1001,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "62322s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:43:52",
      "date_upd": "2014-02-20 15:37:21"
    },
    {
      "id_product": 1002,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 210,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "55931s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:48:17",
      "date_upd": "2014-02-20 15:38:55"
    },
    {
      "id_product": 1003,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "47091s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:52:18",
      "date_upd": "2014-02-17 15:30:35"
    },
    {
      "id_product": 1004,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 616,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "55405s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 13:58:26",
      "date_upd": "2014-02-20 15:37:43"
    },
    {
      "id_product": 1005,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "55122s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 14:03:22",
      "date_upd": "2014-02-20 15:38:08"
    },
    {
      "id_product": 1006,
      "id_supplier": 0,
      "id_manufacturer": 61,
      "id_tax": 3,
      "id_category_default": 16,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 10500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-23",
      "reduction_to": "2014-01-23",
      "reference": "15034s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-23 14:11:57",
      "date_upd": "2014-02-20 15:38:32"
    },
    {
      "id_product": 1007,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 5900,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "000204s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 13:56:25",
      "date_upd": "2022-06-07 13:40:48"
    },
    {
      "id_product": 1008,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2430,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "00129v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 13:58:56",
      "date_upd": "2014-02-20 15:24:28"
    },
    {
      "id_product": 1009,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4425,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "5453s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:02:10",
      "date_upd": "2014-02-20 15:24:50"
    },
    {
      "id_product": 1010,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1675,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "425435s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:08:33",
      "date_upd": "2014-02-20 15:25:13"
    },
    {
      "id_product": 1011,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1650,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "7505s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:14:10",
      "date_upd": "2014-02-20 15:26:22"
    },
    {
      "id_product": 1012,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1575,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "25430s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:16:15",
      "date_upd": "2014-02-20 15:25:41"
    },
    {
      "id_product": 1013,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4950,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "25432s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:20:23",
      "date_upd": "2014-02-20 15:26:44"
    },
    {
      "id_product": 1014,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "000425s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:31:24",
      "date_upd": "2014-02-20 15:27:00"
    },
    {
      "id_product": 1015,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "25433s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:33:26",
      "date_upd": "2014-02-20 15:27:23"
    },
    {
      "id_product": 1016,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2900,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "5785s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:36:37",
      "date_upd": "2014-02-20 15:27:49"
    },
    {
      "id_product": 1017,
      "id_supplier": 0,
      "id_manufacturer": 85,
      "id_tax": 3,
      "id_category_default": 17,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1615,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-24",
      "reduction_to": "2014-01-24",
      "reference": "25427s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-24 14:40:14",
      "date_upd": "2014-02-20 15:28:12"
    },
    {
      "id_product": 1018,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300506692,
      "ecotax": 0,
      "quantity": 1,
      "price": 3400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-28",
      "reduction_to": "2014-01-28",
      "reference": 692,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-28 13:08:47",
      "date_upd": "2014-02-17 15:49:37"
    },
    {
      "id_product": 1019,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300382128,
      "ecotax": 0,
      "quantity": 10,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-28",
      "reduction_to": "2014-01-28",
      "reference": 130793,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-28 13:13:13",
      "date_upd": "2023-09-06 12:24:32"
    },
    {
      "id_product": 1020,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300507095,
      "ecotax": 0,
      "quantity": 2,
      "price": 3400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": "095",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:29:07",
      "date_upd": "2014-02-21 10:03:41"
    },
    {
      "id_product": 1021,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300288857,
      "ecotax": 0,
      "quantity": 6,
      "price": 18500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": "000344",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:32:02",
      "date_upd": "2014-02-17 15:50:56"
    },
    {
      "id_product": 1022,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 36500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 15038,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:36:57",
      "date_upd": "2014-03-23 09:38:34"
    },
    {
      "id_product": 1023,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300967103,
      "ecotax": 0,
      "quantity": 1,
      "price": 39000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 103,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:41:25",
      "date_upd": "2014-02-17 15:51:45"
    },
    {
      "id_product": 1024,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300506791,
      "ecotax": 0,
      "quantity": 1,
      "price": 6500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 791,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:47:41",
      "date_upd": "2014-03-18 15:33:04"
    },
    {
      "id_product": 1025,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300506715,
      "ecotax": 0,
      "quantity": 2,
      "price": 5750,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 130793,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:51:20",
      "date_upd": "2014-01-29 09:51:42"
    },
    {
      "id_product": 1026,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 66000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 15040,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:56:01",
      "date_upd": "2014-02-17 15:53:28"
    },
    {
      "id_product": 1027,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 10000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 19485,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 09:59:16",
      "date_upd": "2014-02-17 15:54:00"
    },
    {
      "id_product": 1028,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300351599,
      "ecotax": 0,
      "quantity": 1,
      "price": 9500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 599,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:02:45",
      "date_upd": "2014-02-17 15:54:37"
    },
    {
      "id_product": 1029,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300507132,
      "ecotax": 0,
      "quantity": 2,
      "price": 3400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 130774,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:05:50",
      "date_upd": "2014-02-17 15:54:57"
    },
    {
      "id_product": 1030,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300507118,
      "ecotax": 0,
      "quantity": 2,
      "price": 3400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": "0507118",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:09:45",
      "date_upd": "2014-02-17 16:00:01"
    },
    {
      "id_product": 1031,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 8900,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 957,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:14:40",
      "date_upd": "2014-02-17 15:55:59"
    },
    {
      "id_product": 1032,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508153,
      "ecotax": 0,
      "quantity": 1,
      "price": 6556,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 153,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:38:36",
      "date_upd": "2014-02-17 15:56:50"
    },
    {
      "id_product": 1033,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321355843,
      "ecotax": 0,
      "quantity": 1,
      "price": 113960,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": "039",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:51:08",
      "date_upd": "2014-02-17 15:57:36"
    },
    {
      "id_product": 1034,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 37,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 12628,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": "028",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 10:58:50",
      "date_upd": "2014-02-17 15:58:09"
    },
    {
      "id_product": 1035,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321284723,
      "ecotax": 0,
      "quantity": 1,
      "price": 68000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 18196,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 13:47:01",
      "date_upd": "2014-02-20 13:57:21"
    },
    {
      "id_product": 1036,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508429,
      "ecotax": 0,
      "quantity": 1,
      "price": 6365,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 8429,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 14:56:19",
      "date_upd": "2014-02-20 13:57:44"
    },
    {
      "id_product": 1037,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 4,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300015804,
      "ecotax": 0,
      "quantity": 1,
      "price": 6500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 508344,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 14:59:20",
      "date_upd": "2014-02-20 13:58:05"
    },
    {
      "id_product": 1038,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508368,
      "ecotax": 0,
      "quantity": 1,
      "price": 6500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 508368,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 15:01:38",
      "date_upd": "2014-02-20 13:58:27"
    },
    {
      "id_product": 1039,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508382,
      "ecotax": 0,
      "quantity": 1,
      "price": 8800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 8382,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 15:03:59",
      "date_upd": "2014-02-20 13:58:54"
    },
    {
      "id_product": 1040,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508788,
      "ecotax": 0,
      "quantity": 1,
      "price": 7800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-29",
      "reduction_to": "2014-01-29",
      "reference": 8788,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-29 15:07:28",
      "date_upd": "2014-02-20 13:59:13"
    },
    {
      "id_product": 1041,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082640,
      "ecotax": 0,
      "quantity": 1,
      "price": 13000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "2640v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:13:58",
      "date_upd": "2014-02-20 13:59:35"
    },
    {
      "id_product": 1042,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082510,
      "ecotax": 0,
      "quantity": 1,
      "price": 11700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": 2510,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:16:30",
      "date_upd": "2014-02-20 14:00:30"
    },
    {
      "id_product": 1043,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082626,
      "ecotax": 0,
      "quantity": 1,
      "price": 11700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "2626v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:20:39",
      "date_upd": "2014-02-20 13:59:55"
    },
    {
      "id_product": 1044,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4006584412990,
      "ecotax": 0,
      "quantity": 1,
      "price": 15654,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "2990v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:22:58",
      "date_upd": "2014-02-20 14:01:03"
    },
    {
      "id_product": 1045,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508801,
      "ecotax": 0,
      "quantity": 1,
      "price": 6530,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "801v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:25:00",
      "date_upd": "2014-04-10 15:57:05"
    },
    {
      "id_product": 1046,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300508825,
      "ecotax": 0,
      "quantity": 1,
      "price": 6530,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": 825,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:27:14",
      "date_upd": "2014-04-10 15:57:44"
    },
    {
      "id_product": 1047,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321081346,
      "ecotax": 0,
      "quantity": 1,
      "price": 18000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": 18213,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:29:16",
      "date_upd": "2014-02-20 14:02:13"
    },
    {
      "id_product": 1048,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300367767,
      "ecotax": 0,
      "quantity": 1,
      "price": 9000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "7767v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:31:47",
      "date_upd": "2014-01-30 15:32:09"
    },
    {
      "id_product": 1049,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300317205,
      "ecotax": 0,
      "quantity": 1,
      "price": 12000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "130817v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:34:13",
      "date_upd": "2014-02-20 14:02:36"
    },
    {
      "id_product": 1050,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050030043219,
      "ecotax": 0,
      "quantity": 3,
      "price": 9700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "2199v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:38:02",
      "date_upd": "2021-04-22 12:57:34"
    },
    {
      "id_product": 1051,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300450872,
      "ecotax": 0,
      "quantity": 1,
      "price": 9000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:41:25",
      "date_upd": "2014-02-20 14:03:29"
    },
    {
      "id_product": 1052,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321137722,
      "ecotax": 0,
      "quantity": 1,
      "price": 13500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": "7722v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:47:54",
      "date_upd": "2014-02-20 14:03:00"
    },
    {
      "id_product": 1053,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300388458,
      "ecotax": 0,
      "quantity": 1,
      "price": 9000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": 8458,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:50:33",
      "date_upd": "2014-02-20 14:14:16"
    },
    {
      "id_product": 1054,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 2,
      "price": 6000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-01-30",
      "reduction_to": "2014-01-30",
      "reference": 130864,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-01-30 15:52:45",
      "date_upd": "2014-01-30 15:53:03"
    },
    {
      "id_product": 1055,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 170,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0000026v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 11:24:46",
      "date_upd": "2014-02-20 15:40:08"
    },
    {
      "id_product": 1056,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 290,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0000728v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 11:34:11",
      "date_upd": "2014-02-20 15:40:29"
    },
    {
      "id_product": 1057,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0000729v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 12:43:46",
      "date_upd": "2014-02-20 15:40:48"
    },
    {
      "id_product": 1058,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 360,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0000730s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 12:48:29",
      "date_upd": "2014-02-20 15:41:08"
    },
    {
      "id_product": 1059,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 330,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0002749s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 12:52:54",
      "date_upd": "2014-02-20 15:41:46"
    },
    {
      "id_product": 1060,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 360,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0002821s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 12:57:56",
      "date_upd": "2014-02-20 15:41:28"
    },
    {
      "id_product": 1061,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 365,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-02",
      "reduction_to": "2014-02-02",
      "reference": "0002823",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-02 13:06:13",
      "date_upd": "2014-02-20 15:42:15"
    },
    {
      "id_product": 1062,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 390,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002825v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:01:58",
      "date_upd": "2014-02-20 15:42:45"
    },
    {
      "id_product": 1063,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 432,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002827v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:05:58",
      "date_upd": "2014-02-20 15:43:09"
    },
    {
      "id_product": 1064,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 475,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002829v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:08:25",
      "date_upd": "2014-02-20 15:43:31"
    },
    {
      "id_product": 1065,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 496,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002831v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:10:40",
      "date_upd": "2014-02-20 15:43:55"
    },
    {
      "id_product": 1066,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 190,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000707v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:17:52",
      "date_upd": "2014-02-20 15:08:40"
    },
    {
      "id_product": 1067,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 190,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000708",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:22:46",
      "date_upd": "2014-02-20 15:09:25"
    },
    {
      "id_product": 1068,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 207,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000709",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:28:58",
      "date_upd": "2014-02-20 15:09:45"
    },
    {
      "id_product": 1069,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 227,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000710",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:31:01",
      "date_upd": "2014-02-20 15:10:05"
    },
    {
      "id_product": 1070,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 223,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000711",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:38:45",
      "date_upd": "2014-02-20 15:14:53"
    },
    {
      "id_product": 1071,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 292,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000712",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:40:44",
      "date_upd": "2014-02-20 15:15:15"
    },
    {
      "id_product": 1072,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 292,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000713",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:42:47",
      "date_upd": "2014-02-20 15:15:35"
    },
    {
      "id_product": 1073,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 42,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 315,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000714",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:45:44",
      "date_upd": "2014-02-20 15:15:58"
    },
    {
      "id_product": 1074,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 309,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002752",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:51:02",
      "date_upd": "2014-02-20 14:56:15"
    },
    {
      "id_product": 1075,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 358,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002753",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 09:56:04",
      "date_upd": "2014-02-20 14:56:35"
    },
    {
      "id_product": 1076,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 376,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002754",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:15:54",
      "date_upd": "2014-02-20 15:01:45"
    },
    {
      "id_product": 1077,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 427,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002750",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:19:13",
      "date_upd": "2014-02-20 15:02:06"
    },
    {
      "id_product": 1078,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 402,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002820",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:21:24",
      "date_upd": "2014-02-20 15:02:26"
    },
    {
      "id_product": 1079,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 411,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002822",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:25:37",
      "date_upd": "2014-02-20 15:02:47"
    },
    {
      "id_product": 1080,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2824,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:29:11",
      "date_upd": "2014-02-20 15:03:06"
    },
    {
      "id_product": 1081,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 468,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002826",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:32:19",
      "date_upd": "2014-02-20 15:03:28"
    },
    {
      "id_product": 1082,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 484,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002828484",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:35:01",
      "date_upd": "2014-02-20 15:03:50"
    },
    {
      "id_product": 1083,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 31,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 502,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0002830",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:36:59",
      "date_upd": "2014-07-01 08:49:38"
    },
    {
      "id_product": 1084,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "0000727",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 10:41:15",
      "date_upd": "2014-02-20 15:05:27"
    },
    {
      "id_product": 1085,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082107,
      "ecotax": 0,
      "quantity": 1,
      "price": 20372,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "8096s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:08:16",
      "date_upd": "2014-02-20 14:14:39"
    },
    {
      "id_product": 1086,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321081353,
      "ecotax": 0,
      "quantity": 1,
      "price": 13860,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": 8177,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:15:21",
      "date_upd": "2014-02-20 14:14:58"
    },
    {
      "id_product": 1087,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082091,
      "ecotax": 0,
      "quantity": 1,
      "price": 15620,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": 8195,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:21:08",
      "date_upd": "2014-02-20 14:15:19"
    },
    {
      "id_product": 1088,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082084,
      "ecotax": 0,
      "quantity": 1,
      "price": 3764,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "8210s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:28:06",
      "date_upd": "2014-02-20 14:15:42"
    },
    {
      "id_product": 1089,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 19888,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "9040s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:31:52",
      "date_upd": "2014-02-20 14:16:02"
    },
    {
      "id_product": 1090,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321082360,
      "ecotax": 0,
      "quantity": 1,
      "price": 19888,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "9530s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:35:06",
      "date_upd": "2014-02-20 14:16:42"
    },
    {
      "id_product": 1091,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 19580,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": 8230,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:40:18",
      "date_upd": "2014-02-20 14:16:22"
    },
    {
      "id_product": 1092,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 30492,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": 8120,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:44:11",
      "date_upd": "2014-02-20 14:17:07"
    },
    {
      "id_product": 1093,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 25300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": 8155,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:47:31",
      "date_upd": "2014-02-20 14:17:30"
    },
    {
      "id_product": 1094,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 29964,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "8147s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:50:43",
      "date_upd": "2014-02-20 14:17:51"
    },
    {
      "id_product": 1095,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 27500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "8150s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:54:37",
      "date_upd": "2014-02-20 14:18:11"
    },
    {
      "id_product": 1096,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-03",
      "reduction_to": "2014-02-03",
      "reference": "8157s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-03 15:59:08",
      "date_upd": "2014-02-20 14:18:29"
    },
    {
      "id_product": 1097,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000643v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:31:13",
      "date_upd": "2014-02-20 15:05:47"
    },
    {
      "id_product": 1098,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000644v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:35:16",
      "date_upd": "2014-02-20 15:06:10"
    },
    {
      "id_product": 1099,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 303,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002226",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:38:49",
      "date_upd": "2014-02-20 15:06:30"
    },
    {
      "id_product": 1100,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 303,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002225v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:40:45",
      "date_upd": "2014-02-20 15:06:48"
    },
    {
      "id_product": 1101,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 337,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002222",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:43:02",
      "date_upd": "2014-02-20 15:07:08"
    },
    {
      "id_product": 1102,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 394,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002185",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:48:30",
      "date_upd": "2014-02-20 15:07:33"
    },
    {
      "id_product": 1103,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 445,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002223v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 08:50:34",
      "date_upd": "2014-02-20 15:07:55"
    },
    {
      "id_product": 1104,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 66352,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "8199s",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 09:07:27",
      "date_upd": "2014-02-20 14:18:48"
    },
    {
      "id_product": 1105,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321064875,
      "ecotax": 0,
      "quantity": 1,
      "price": 46200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": 8165,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 09:11:51",
      "date_upd": "2014-02-20 14:19:10"
    },
    {
      "id_product": 1106,
      "id_supplier": 0,
      "id_manufacturer": 76,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 4,
      "price": 11000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": 130813,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 09:16:56",
      "date_upd": "2014-02-04 09:18:24"
    },
    {
      "id_product": 1107,
      "id_supplier": 0,
      "id_manufacturer": 76,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 11000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": 130835,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 09:20:12",
      "date_upd": "2014-02-04 09:20:57"
    },
    {
      "id_product": 1108,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 325,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000715",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:00:57",
      "date_upd": "2014-02-20 14:35:16"
    },
    {
      "id_product": 1109,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 0,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000716",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:02:41",
      "date_upd": "2014-02-20 14:35:41"
    },
    {
      "id_product": 1110,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 423,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000717",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:05:01",
      "date_upd": "2014-02-20 14:36:01"
    },
    {
      "id_product": 1111,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 501,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000718",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:08:01",
      "date_upd": "2014-02-20 14:36:40"
    },
    {
      "id_product": 1112,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 354,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0000735",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:12:31",
      "date_upd": "2014-02-20 14:23:07"
    },
    {
      "id_product": 1113,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 354,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002755",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:20:51",
      "date_upd": "2014-02-20 14:23:26"
    },
    {
      "id_product": 1114,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 423,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-04",
      "reduction_to": "2014-02-04",
      "reference": "0002756",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-04 10:25:15",
      "date_upd": "2014-02-20 14:27:22"
    },
    {
      "id_product": 1115,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 433,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002751",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 08:39:56",
      "date_upd": "2014-02-20 14:27:43"
    },
    {
      "id_product": 1116,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 411,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002757",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 08:45:01",
      "date_upd": "2014-02-20 14:28:09"
    },
    {
      "id_product": 1117,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 490,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002758",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 08:48:18",
      "date_upd": "2014-02-20 14:28:39"
    },
    {
      "id_product": 1118,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 501,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002759",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 08:51:23",
      "date_upd": "2014-02-20 14:29:05"
    },
    {
      "id_product": 1119,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 274,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0000719",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 08:55:56",
      "date_upd": "2014-02-20 14:29:40"
    },
    {
      "id_product": 1120,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 297,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0000725",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:00:04",
      "date_upd": "2014-02-20 14:30:07"
    },
    {
      "id_product": 1121,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 337,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0000726",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:02:00",
      "date_upd": "2014-02-20 14:30:39"
    },
    {
      "id_product": 1122,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 292,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002720",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:06:14",
      "date_upd": "2014-02-20 14:31:07"
    },
    {
      "id_product": 1123,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 337,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002721",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:08:57",
      "date_upd": "2014-02-20 14:31:28"
    },
    {
      "id_product": 1124,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 372,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002722",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:11:37",
      "date_upd": "2014-02-20 14:32:00"
    },
    {
      "id_product": 1125,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 402,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002723",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:14:48",
      "date_upd": "2014-02-20 14:32:28"
    },
    {
      "id_product": 1126,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 223,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002309",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:19:58",
      "date_upd": "2014-02-20 14:32:54"
    },
    {
      "id_product": 1127,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 36,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002310",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:23:41",
      "date_upd": "2014-02-20 14:33:13"
    },
    {
      "id_product": 1128,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 41,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 261,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002311",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:27:44",
      "date_upd": "2014-02-20 14:33:36"
    },
    {
      "id_product": 1129,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 168,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002300",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:32:26",
      "date_upd": "2014-02-20 14:21:34"
    },
    {
      "id_product": 1130,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 201,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002313",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:34:35",
      "date_upd": "2014-02-20 14:21:53"
    },
    {
      "id_product": 1131,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 235,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002314",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:37:25",
      "date_upd": "2014-02-20 14:22:15"
    },
    {
      "id_product": 1132,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 243,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-05",
      "reduction_to": "2014-02-05",
      "reference": "0002304",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-05 09:39:15",
      "date_upd": "2014-02-20 14:22:35"
    },
    {
      "id_product": 1135,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928048005211,
      "ecotax": 0,
      "quantity": 1,
      "price": 170,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": 211,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 14:45:44",
      "date_upd": "2014-02-20 15:16:36"
    },
    {
      "id_product": 1136,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928010000101,
      "ecotax": 0,
      "quantity": 1,
      "price": 1460,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "101v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 14:52:13",
      "date_upd": "2014-02-20 15:16:57"
    },
    {
      "id_product": 1137,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928010001201,
      "ecotax": 0,
      "quantity": 1,
      "price": 1500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "201v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 14:58:47",
      "date_upd": "2017-02-16 14:05:15"
    },
    {
      "id_product": 1138,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928003505203,
      "ecotax": 0,
      "quantity": 0,
      "price": 400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "203v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 15:05:44",
      "date_upd": "2014-09-24 12:57:29"
    },
    {
      "id_product": 1139,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 4,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928011300101,
      "ecotax": 0,
      "quantity": 1,
      "price": 2350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "0101v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 15:08:35",
      "date_upd": "2019-11-18 13:34:15"
    },
    {
      "id_product": 1140,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928011301201,
      "ecotax": 0,
      "quantity": 1,
      "price": 2158,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "0201v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 15:15:10",
      "date_upd": "2014-02-20 15:18:46"
    },
    {
      "id_product": 1141,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928034901229,
      "ecotax": 0,
      "quantity": 1,
      "price": 2158,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "1229v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 15:18:48",
      "date_upd": "2014-02-20 15:19:10"
    },
    {
      "id_product": 1142,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927978500129,
      "ecotax": 0,
      "quantity": 1,
      "price": 2659,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-06",
      "reduction_to": "2014-02-06",
      "reference": "129vv",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-06 15:23:42",
      "date_upd": "2014-02-20 15:19:39"
    },
    {
      "id_product": 1143,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928035200101,
      "ecotax": 0,
      "quantity": 1,
      "price": 3280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-07",
      "reduction_to": "2014-02-07",
      "reference": 200101,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-07 09:43:25",
      "date_upd": "2014-02-20 15:20:51"
    },
    {
      "id_product": 1144,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927901700121,
      "ecotax": 0,
      "quantity": 1,
      "price": 930,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-07",
      "reduction_to": "2014-02-07",
      "reference": "0121",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-07 09:45:57",
      "date_upd": "2014-02-20 15:20:04"
    },
    {
      "id_product": 1146,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927904105206,
      "ecotax": 0,
      "quantity": 1,
      "price": 184,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-07",
      "reduction_to": "2014-02-07",
      "reference": 5206,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-07 09:52:00",
      "date_upd": "2014-02-20 15:21:08"
    },
    {
      "id_product": 1147,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927903400121,
      "ecotax": 0,
      "quantity": 1,
      "price": 1574,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-07",
      "reduction_to": "2014-02-07",
      "reference": "00121",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-07 09:54:36",
      "date_upd": "2014-02-20 15:21:30"
    },
    {
      "id_product": 1148,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321543929,
      "ecotax": 0,
      "quantity": 0,
      "price": 999,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-10",
      "reduction_to": "2014-02-10",
      "reference": "008640v",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 1,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-10 13:41:16",
      "date_upd": "2022-12-14 13:03:39"
    },
    {
      "id_product": 1149,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321694676,
      "ecotax": 0,
      "quantity": 1,
      "price": 738,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-10",
      "reduction_to": "2014-02-10",
      "reference": 4676,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-10 13:44:47",
      "date_upd": "2014-02-20 15:22:30"
    },
    {
      "id_product": 1150,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321694690,
      "ecotax": 0,
      "quantity": 1,
      "price": 2566,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-10",
      "reduction_to": "2014-02-10",
      "reference": 4690,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-10 13:50:14",
      "date_upd": "2014-02-20 15:22:49"
    },
    {
      "id_product": 1151,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 1,
      "ean13": 4008321694713,
      "ecotax": 0,
      "quantity": 1,
      "price": 3190,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-10",
      "reduction_to": "2014-02-10",
      "reference": 4713,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-10 13:55:50",
      "date_upd": "2014-02-20 15:23:06"
    },
    {
      "id_product": 1152,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 15,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321912848,
      "ecotax": 0,
      "quantity": 1,
      "price": 1500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-10",
      "reduction_to": "2014-02-10",
      "reference": 2848,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-10 14:03:24",
      "date_upd": "2014-02-20 15:23:27"
    },
    {
      "id_product": 1153,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000731",
      "ecotax": 0,
      "quantity": 1,
      "price": 316,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000731",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:01:47",
      "date_upd": "2014-02-20 14:37:34"
    },
    {
      "id_product": 1154,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000732",
      "ecotax": 0,
      "quantity": 1,
      "price": 309,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000732",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:05:55",
      "date_upd": "2014-02-20 14:37:57"
    },
    {
      "id_product": 1155,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000733",
      "ecotax": 0,
      "quantity": 1,
      "price": 343,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000733",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:09:17",
      "date_upd": "2014-02-20 14:38:15"
    },
    {
      "id_product": 1156,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000734",
      "ecotax": 0,
      "quantity": 1,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000734",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:11:26",
      "date_upd": "2014-02-20 14:40:35"
    },
    {
      "id_product": 1157,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000741",
      "ecotax": 0,
      "quantity": 0,
      "price": 297,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000741",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 0,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:13:27",
      "date_upd": "2017-07-10 09:33:35"
    },
    {
      "id_product": 1158,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000742",
      "ecotax": 0,
      "quantity": 1,
      "price": 309,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000742",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:15:28",
      "date_upd": "2014-02-20 14:41:25"
    },
    {
      "id_product": 1159,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000743",
      "ecotax": 0,
      "quantity": 1,
      "price": 9999999.999999,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000743",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:17:00",
      "date_upd": "2023-08-18 08:23:51"
    },
    {
      "id_product": 1160,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 43,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0000744",
      "ecotax": 0,
      "quantity": 1,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0000744",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:19:01",
      "date_upd": "2014-02-20 14:42:05"
    },
    {
      "id_product": 1161,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0027276",
      "ecotax": 0,
      "quantity": 1,
      "price": 504,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0027276",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:22:40",
      "date_upd": "2014-02-20 14:42:37"
    },
    {
      "id_product": 1162,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0033005",
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0033005",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:27:49",
      "date_upd": "2014-02-20 14:43:07"
    },
    {
      "id_product": 1163,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0033006",
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0033006",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:31:51",
      "date_upd": "2014-02-20 14:43:27"
    },
    {
      "id_product": 1164,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0033050",
      "ecotax": 0,
      "quantity": 1,
      "price": 353,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0033050",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:35:27",
      "date_upd": "2014-02-20 14:43:47"
    },
    {
      "id_product": 1166,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0033052",
      "ecotax": 0,
      "quantity": 1,
      "price": 331,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0033052",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:52:29",
      "date_upd": "2014-02-20 14:44:22"
    },
    {
      "id_product": 1167,
      "id_supplier": 0,
      "id_manufacturer": 80,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "0001663",
      "ecotax": 0,
      "quantity": 1,
      "price": 354,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-13",
      "reduction_to": "2014-02-13",
      "reference": "0001663",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-13 10:57:17",
      "date_upd": "2014-02-20 14:44:42"
    },
    {
      "id_product": 1168,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321378316,
      "ecotax": 0,
      "quantity": 1,
      "price": 140,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 8316,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 10:42:43",
      "date_upd": "2016-02-26 10:06:08"
    },
    {
      "id_product": 1169,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321378330,
      "ecotax": 0,
      "quantity": 1,
      "price": 150,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 8330,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 10:47:49",
      "date_upd": "2016-02-26 10:06:45"
    },
    {
      "id_product": 1170,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321378385,
      "ecotax": 0,
      "quantity": 1,
      "price": 157.024793,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 8385,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 10:50:52",
      "date_upd": "2016-02-26 10:07:18"
    },
    {
      "id_product": 1171,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321468796,
      "ecotax": 0,
      "quantity": 6,
      "price": 230,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 8796,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 10:52:56",
      "date_upd": "2020-04-24 08:31:34"
    },
    {
      "id_product": 1172,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321620774,
      "ecotax": 0,
      "quantity": 1,
      "price": 250,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": "0774",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 0,
      "indexed": 1,
      "date_add": "2014-02-14 10:57:28",
      "date_upd": "2016-02-26 10:09:27"
    },
    {
      "id_product": 1173,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321398826,
      "ecotax": 0,
      "quantity": 1,
      "price": 225,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 8826,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 11:00:02",
      "date_upd": "2016-02-26 10:10:24"
    },
    {
      "id_product": 1174,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321054258,
      "ecotax": 0,
      "quantity": 1,
      "price": 300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 54258,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:26:36",
      "date_upd": "2016-02-26 10:10:56"
    },
    {
      "id_product": 1175,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321398888,
      "ecotax": 0,
      "quantity": 1,
      "price": 330,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 8888,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:28:26",
      "date_upd": "2016-02-26 10:13:07"
    },
    {
      "id_product": 1176,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321054319,
      "ecotax": 0,
      "quantity": 0,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 54319,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:31:17",
      "date_upd": "2020-06-11 14:06:11"
    },
    {
      "id_product": 1177,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321220431,
      "ecotax": 0,
      "quantity": 2,
      "price": 272.727273,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 20431,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:33:21",
      "date_upd": "2020-04-02 13:06:28"
    },
    {
      "id_product": 1178,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321220561,
      "ecotax": 0,
      "quantity": 0,
      "price": 278,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 20561,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 0,
      "indexed": 1,
      "date_add": "2014-02-14 15:37:26",
      "date_upd": "2020-04-02 14:37:59"
    },
    {
      "id_product": 1179,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321220684,
      "ecotax": 0,
      "quantity": 2,
      "price": 327,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": "0684",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 0,
      "indexed": 1,
      "date_add": "2014-02-14 15:39:37",
      "date_upd": "2017-07-24 09:28:59"
    },
    {
      "id_product": 1180,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321229946,
      "ecotax": 0,
      "quantity": 6,
      "price": 140,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 9946,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:43:24",
      "date_upd": "2014-03-09 11:01:31"
    },
    {
      "id_product": 1181,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300941202,
      "ecotax": 0,
      "quantity": 2,
      "price": 156,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 1202,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:48:41",
      "date_upd": "2020-04-24 10:14:14"
    },
    {
      "id_product": 1182,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300941226,
      "ecotax": 0,
      "quantity": 5,
      "price": 160,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 41226,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:51:21",
      "date_upd": "2020-04-24 10:16:25"
    },
    {
      "id_product": 1183,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4050300941240,
      "ecotax": 0,
      "quantity": 0,
      "price": 222,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 1240,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 0,
      "indexed": 1,
      "date_add": "2014-02-14 15:55:11",
      "date_upd": "2020-04-24 10:15:11"
    },
    {
      "id_product": 1184,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321383891,
      "ecotax": 2.5,
      "quantity": 0,
      "price": 190,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 3891,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 15:57:13",
      "date_upd": "2020-06-10 08:34:03"
    },
    {
      "id_product": 1185,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321384911,
      "ecotax": 0,
      "quantity": 1,
      "price": 156,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 4911,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 16:02:19",
      "date_upd": "2014-03-09 11:09:10"
    },
    {
      "id_product": 1186,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 4008321384935,
      "ecotax": 0,
      "quantity": 1,
      "price": 181,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-14",
      "reduction_to": "2014-02-14",
      "reference": 4935,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-14 16:04:35",
      "date_upd": "2014-03-09 11:10:00"
    },
    {
      "id_product": 1187,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928000104013,
      "ecotax": 0,
      "quantity": 4,
      "price": 160,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-15",
      "reduction_to": "2014-02-15",
      "reference": 4013,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-15 14:56:15",
      "date_upd": "2014-02-17 15:44:41"
    },
    {
      "id_product": 1188,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928000704013,
      "ecotax": 0,
      "quantity": 4,
      "price": 180,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-15",
      "reduction_to": "2014-02-15",
      "reference": "04013",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-15 15:00:37",
      "date_upd": "2014-02-17 15:45:00"
    },
    {
      "id_product": 1189,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928001104013,
      "ecotax": 0,
      "quantity": 1,
      "price": 198,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-15",
      "reduction_to": "2014-02-15",
      "reference": 1104013,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-15 15:02:36",
      "date_upd": "2014-02-17 15:45:28"
    },
    {
      "id_product": 1190,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928002204013,
      "ecotax": 0,
      "quantity": 1,
      "price": 210,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-15",
      "reduction_to": "2014-02-15",
      "reference": "02204013",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-15 15:05:33",
      "date_upd": "2014-02-17 15:45:57"
    },
    {
      "id_product": 1191,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928002004013,
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-15",
      "reduction_to": "2014-02-15",
      "reference": "004013",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-15 15:07:42",
      "date_upd": "2014-02-17 15:46:27"
    },
    {
      "id_product": 1192,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 928003404013,
      "ecotax": 0,
      "quantity": 0,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-15",
      "reduction_to": "2014-02-15",
      "reference": "003404013",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-15 15:09:52",
      "date_upd": "2016-02-07 08:32:17"
    },
    {
      "id_product": 1193,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927971204099,
      "ecotax": 0,
      "quantity": 1,
      "price": 440,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-16",
      "reduction_to": "2014-02-16",
      "reference": "04099",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-16 11:22:24",
      "date_upd": "2014-02-17 15:47:12"
    },
    {
      "id_product": 1194,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927971404099,
      "ecotax": 0,
      "quantity": 1,
      "price": 480,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-16",
      "reduction_to": "2014-02-16",
      "reference": 404099,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-16 11:25:08",
      "date_upd": "2014-02-17 15:47:34"
    },
    {
      "id_product": 1195,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927973404099,
      "ecotax": 0,
      "quantity": 1,
      "price": 520,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-16",
      "reduction_to": "2014-02-16",
      "reference": 27973404099,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-16 11:27:36",
      "date_upd": "2014-02-17 15:47:53"
    },
    {
      "id_product": 1196,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": 927970204099,
      "ecotax": 0,
      "quantity": 35,
      "price": 536,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-16",
      "reduction_to": "2014-02-16",
      "reference": 27970204099,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-16 14:04:56",
      "date_upd": "2020-05-07 13:40:13"
    },
    {
      "id_product": 1197,
      "id_supplier": 0,
      "id_manufacturer": 66,
      "id_tax": 3,
      "id_category_default": 36,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 20,
      "price": 819,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-21",
      "reduction_to": "2014-02-21",
      "reference": 130414,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-21 13:49:46",
      "date_upd": "2020-10-19 10:26:10"
    },
    {
      "id_product": 1198,
      "id_supplier": 0,
      "id_manufacturer": 81,
      "id_tax": 3,
      "id_category_default": 36,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 20,
      "price": 60,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-21",
      "reduction_to": "2014-02-21",
      "reference": "0000166",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-21 13:55:11",
      "date_upd": "2014-02-21 13:55:46"
    },
    {
      "id_product": 1199,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 36,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 4,
      "price": 616,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-21",
      "reduction_to": "2014-02-21",
      "reference": "130490k",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-21 13:59:09",
      "date_upd": "2014-02-21 13:59:45"
    },
    {
      "id_product": 1203,
      "id_supplier": 0,
      "id_manufacturer": 84,
      "id_tax": 4,
      "id_category_default": 36,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 1000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-21",
      "reduction_to": "2014-02-21",
      "reference": 131691,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 0,
      "indexed": 1,
      "date_add": "2014-02-21 14:11:33",
      "date_upd": "2018-11-06 10:21:43"
    },
    {
      "id_product": 1204,
      "id_supplier": 0,
      "id_manufacturer": 84,
      "id_tax": 3,
      "id_category_default": 36,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 4,
      "price": 1000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-21",
      "reduction_to": "2014-02-21",
      "reference": 200,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-21 14:22:56",
      "date_upd": "2014-02-21 14:23:49"
    },
    {
      "id_product": 1205,
      "id_supplier": 0,
      "id_manufacturer": 84,
      "id_tax": 3,
      "id_category_default": 36,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-02-24",
      "reduction_to": "2014-02-24",
      "reference": 130809,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-02-24 09:35:19",
      "date_upd": "2014-02-24 09:38:30"
    },
    {
      "id_product": 1206,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1832,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-10",
      "reduction_to": "2014-03-10",
      "reference": 125201,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-10 10:07:35",
      "date_upd": "2014-03-18 07:34:58"
    },
    {
      "id_product": 1207,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1726,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125202,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 10:53:01",
      "date_upd": "2014-03-11 14:44:21"
    },
    {
      "id_product": 1208,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125203,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 11:00:39",
      "date_upd": "2014-03-11 14:43:21"
    },
    {
      "id_product": 1209,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125204,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 11:03:57",
      "date_upd": "2014-03-11 14:42:13"
    },
    {
      "id_product": 1210,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125205,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 11:11:11",
      "date_upd": "2014-03-11 14:41:06"
    },
    {
      "id_product": 1211,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125206,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 11:55:11",
      "date_upd": "2014-03-11 14:39:50"
    },
    {
      "id_product": 1212,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125207,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 12:02:09",
      "date_upd": "2014-03-11 14:38:16"
    },
    {
      "id_product": 1213,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125208,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 12:05:26",
      "date_upd": "2014-03-11 14:36:50"
    },
    {
      "id_product": 1214,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1100,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125209,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 12:09:33",
      "date_upd": "2014-03-11 14:35:36"
    },
    {
      "id_product": 1215,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2110,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-11",
      "reduction_to": "2014-03-11",
      "reference": 125210,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-11 12:12:51",
      "date_upd": "2014-03-11 14:33:53"
    },
    {
      "id_product": 1216,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125211,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 07:35:57",
      "date_upd": "2014-03-12 14:27:46"
    },
    {
      "id_product": 1217,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2005,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125212,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 07:58:36",
      "date_upd": "2014-03-12 14:29:09"
    },
    {
      "id_product": 1218,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125213,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:01:08",
      "date_upd": "2014-03-12 14:31:20"
    },
    {
      "id_product": 1219,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125214,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:05:34",
      "date_upd": "2014-03-12 14:32:56"
    },
    {
      "id_product": 1220,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2308,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125215,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:10:26",
      "date_upd": "2014-03-12 14:33:55"
    },
    {
      "id_product": 1221,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4127,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125216,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:13:16",
      "date_upd": "2014-03-12 14:34:50"
    },
    {
      "id_product": 1222,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2005,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125217,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:15:44",
      "date_upd": "2014-03-12 14:35:47"
    },
    {
      "id_product": 1223,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125218,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:17:43",
      "date_upd": "2014-03-12 14:36:35"
    },
    {
      "id_product": 1224,
      "id_supplier": 0,
      "id_manufacturer": 87,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3264,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125219,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:19:30",
      "date_upd": "2014-03-12 14:37:33"
    },
    {
      "id_product": 1225,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125100,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:23:34",
      "date_upd": "2014-03-12 14:39:00"
    },
    {
      "id_product": 1226,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 210,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125101,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:25:24",
      "date_upd": "2014-03-12 14:40:07"
    },
    {
      "id_product": 1227,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125114,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 08:28:09",
      "date_upd": "2014-03-12 14:41:38"
    },
    {
      "id_product": 1228,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 419,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125117,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 09:57:59",
      "date_upd": "2014-03-12 14:42:39"
    },
    {
      "id_product": 1229,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 419,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125118,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 10:00:05",
      "date_upd": "2014-03-12 14:43:39"
    },
    {
      "id_product": 1230,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 3144,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125238,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 10:02:33",
      "date_upd": "2014-03-12 14:44:25"
    },
    {
      "id_product": 1231,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 349,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125239,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 10:05:38",
      "date_upd": "2014-03-12 14:45:16"
    },
    {
      "id_product": 1232,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 340,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125119,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 10:53:30",
      "date_upd": "2014-03-12 14:46:18"
    },
    {
      "id_product": 1233,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2089,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125240,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 10:55:38",
      "date_upd": "2014-03-12 14:47:11"
    },
    {
      "id_product": 1234,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 522,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125835,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 10:58:57",
      "date_upd": "2014-03-12 14:48:26"
    },
    {
      "id_product": 1235,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1344,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125121,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:01:09",
      "date_upd": "2014-03-12 14:49:29"
    },
    {
      "id_product": 1236,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 502,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125126,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:04:25",
      "date_upd": "2014-03-12 14:50:21"
    },
    {
      "id_product": 1237,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 514,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125128,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:07:15",
      "date_upd": "2014-03-12 14:51:14"
    },
    {
      "id_product": 1238,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125127,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:10:06",
      "date_upd": "2014-03-12 14:52:08"
    },
    {
      "id_product": 1239,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1412,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125241,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:12:44",
      "date_upd": "2014-03-12 14:52:56"
    },
    {
      "id_product": 1240,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 601,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125131,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:15:07",
      "date_upd": "2014-03-12 14:53:43"
    },
    {
      "id_product": 1241,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 711,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125133,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:19:25",
      "date_upd": "2014-03-12 14:54:32"
    },
    {
      "id_product": 1242,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2246,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125120,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:22:15",
      "date_upd": "2014-03-12 14:55:40"
    },
    {
      "id_product": 1243,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2140,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125242,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:24:18",
      "date_upd": "2014-03-12 14:56:31"
    },
    {
      "id_product": 1244,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 575,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125830,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:26:27",
      "date_upd": "2014-03-12 14:57:28"
    },
    {
      "id_product": 1245,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 0,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1625,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125243,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:29:17",
      "date_upd": "2014-03-12 14:58:32"
    },
    {
      "id_product": 1246,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 876,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-12",
      "reduction_to": "2014-03-12",
      "reference": 125244,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-12 11:31:28",
      "date_upd": "2014-03-12 14:59:32"
    },
    {
      "id_product": 1247,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125122,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 08:15:50",
      "date_upd": "2014-03-13 15:32:08"
    },
    {
      "id_product": 1248,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 390,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125123,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:07:33",
      "date_upd": "2014-03-13 15:34:03"
    },
    {
      "id_product": 1249,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 490,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125124,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:09:09",
      "date_upd": "2014-03-13 15:33:11"
    },
    {
      "id_product": 1250,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1252,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125247,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:11:41",
      "date_upd": "2014-03-13 15:35:04"
    },
    {
      "id_product": 1251,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 490,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125248,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:14:02",
      "date_upd": "2014-03-13 15:37:08"
    },
    {
      "id_product": 1252,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 390,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125249,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:17:08",
      "date_upd": "2014-03-13 15:38:08"
    },
    {
      "id_product": 1253,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125831,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:19:50",
      "date_upd": "2014-03-13 15:39:08"
    },
    {
      "id_product": 1254,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 4,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125845,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:23:40",
      "date_upd": "2014-03-13 15:40:30"
    },
    {
      "id_product": 1255,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 590,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125850,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:28:34",
      "date_upd": "2014-03-13 15:41:32"
    },
    {
      "id_product": 1256,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 980,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125852,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:32:57",
      "date_upd": "2014-03-15 10:38:44"
    },
    {
      "id_product": 1257,
      "id_supplier": 0,
      "id_manufacturer": 86,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 460,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125115,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:36:42",
      "date_upd": "2014-03-15 10:39:49"
    },
    {
      "id_product": 1258,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 780,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125169,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:39:00",
      "date_upd": "2014-03-15 10:42:05"
    },
    {
      "id_product": 1259,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 916,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125224,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:40:50",
      "date_upd": "2014-03-15 10:40:51"
    },
    {
      "id_product": 1260,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 720,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125180,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:43:10",
      "date_upd": "2014-03-15 10:42:55"
    },
    {
      "id_product": 1261,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125170,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:45:39",
      "date_upd": "2014-03-15 10:44:18"
    },
    {
      "id_product": 1262,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 490,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125175,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:47:20",
      "date_upd": "2014-03-15 10:45:27"
    },
    {
      "id_product": 1263,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 480,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125171,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:50:11",
      "date_upd": "2014-03-15 10:46:24"
    },
    {
      "id_product": 1264,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 480,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125225,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:52:00",
      "date_upd": "2014-03-15 10:47:19"
    },
    {
      "id_product": 1265,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 470,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125226,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:53:45",
      "date_upd": "2014-03-15 10:48:18"
    },
    {
      "id_product": 1266,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125168,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 10:56:04",
      "date_upd": "2014-03-15 10:50:02"
    },
    {
      "id_product": 1267,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125227,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:00:14",
      "date_upd": "2014-03-15 10:49:07"
    },
    {
      "id_product": 1268,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125181,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:13:18",
      "date_upd": "2014-03-15 10:50:51"
    },
    {
      "id_product": 1269,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 795,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125228,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:15:22",
      "date_upd": "2014-03-15 10:52:36"
    },
    {
      "id_product": 1270,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125176,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:17:30",
      "date_upd": "2014-03-15 10:53:31"
    },
    {
      "id_product": 1271,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 540,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125229,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:19:05",
      "date_upd": "2014-03-15 10:54:33"
    },
    {
      "id_product": 1272,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 490,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125109,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:22:03",
      "date_upd": "2014-03-15 10:55:27"
    },
    {
      "id_product": 1273,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 560,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125172,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:23:47",
      "date_upd": "2014-03-15 10:56:20"
    },
    {
      "id_product": 1274,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 920,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125182,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:51:59",
      "date_upd": "2014-03-15 10:57:25"
    },
    {
      "id_product": 1275,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125110,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:56:18",
      "date_upd": "2014-03-15 10:58:16"
    },
    {
      "id_product": 1276,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 670,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125183,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 11:58:34",
      "date_upd": "2014-03-15 10:59:15"
    },
    {
      "id_product": 1277,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 480,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125177,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:01:10",
      "date_upd": "2014-03-15 11:00:10"
    },
    {
      "id_product": 1278,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125178,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:03:43",
      "date_upd": "2014-03-15 11:01:09"
    },
    {
      "id_product": 1279,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 127175,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:06:36",
      "date_upd": "2014-03-15 11:02:10"
    },
    {
      "id_product": 1280,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125236,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:08:46",
      "date_upd": "2014-03-15 11:03:13"
    },
    {
      "id_product": 1281,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 560,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125184,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:11:00",
      "date_upd": "2014-03-15 11:04:13"
    },
    {
      "id_product": 1282,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 760,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125237,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:12:42",
      "date_upd": "2014-03-15 11:05:07"
    },
    {
      "id_product": 1283,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 720,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125187,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:14:34",
      "date_upd": "2014-03-15 11:05:59"
    },
    {
      "id_product": 1284,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125188,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:17:12",
      "date_upd": "2014-03-15 11:07:10"
    },
    {
      "id_product": 1285,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1406,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125221,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:19:31",
      "date_upd": "2014-03-15 11:08:20"
    },
    {
      "id_product": 1286,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125189,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:22:10",
      "date_upd": "2014-03-15 11:09:19"
    },
    {
      "id_product": 1287,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 780,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125173,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:26:46",
      "date_upd": "2014-03-15 11:10:19"
    },
    {
      "id_product": 1288,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1100,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-13",
      "reduction_to": "2014-03-13",
      "reference": 125174,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-13 12:29:11",
      "date_upd": "2014-03-15 11:11:16"
    },
    {
      "id_product": 1289,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 820,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125190,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:12:24",
      "date_upd": "2014-03-15 11:12:14"
    },
    {
      "id_product": 1290,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125261,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:14:22",
      "date_upd": "2014-03-15 11:13:04"
    },
    {
      "id_product": 1291,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125262,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:16:00",
      "date_upd": "2014-03-15 11:14:05"
    },
    {
      "id_product": 1292,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 750,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125185,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:19:55",
      "date_upd": "2014-03-15 11:14:56"
    },
    {
      "id_product": 1293,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125222,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:21:38",
      "date_upd": "2014-03-15 11:18:38"
    },
    {
      "id_product": 1294,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 750,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125186,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:25:58",
      "date_upd": "2014-03-15 11:19:48"
    },
    {
      "id_product": 1295,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125223,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:28:57",
      "date_upd": "2014-03-15 11:20:39"
    },
    {
      "id_product": 1296,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125113,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:31:15",
      "date_upd": "2014-03-15 11:21:39"
    },
    {
      "id_product": 1297,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125112,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:32:56",
      "date_upd": "2014-03-15 11:22:31"
    },
    {
      "id_product": 1298,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 250,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125106,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:35:05",
      "date_upd": "2014-03-15 11:23:29"
    },
    {
      "id_product": 1299,
      "id_supplier": 0,
      "id_manufacturer": 70,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 250,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125255,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:36:42",
      "date_upd": "2014-03-15 11:24:21"
    },
    {
      "id_product": 1300,
      "id_supplier": 0,
      "id_manufacturer": 89,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 250,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125010,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:41:07",
      "date_upd": "2014-03-15 11:25:11"
    },
    {
      "id_product": 1301,
      "id_supplier": 0,
      "id_manufacturer": 89,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125000,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:43:28",
      "date_upd": "2014-03-16 08:55:23"
    },
    {
      "id_product": 1302,
      "id_supplier": 0,
      "id_manufacturer": 89,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125030,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:46:40",
      "date_upd": "2014-03-16 08:56:29"
    },
    {
      "id_product": 1303,
      "id_supplier": 0,
      "id_manufacturer": 89,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 400,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125020,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:48:59",
      "date_upd": "2014-03-16 08:57:34"
    },
    {
      "id_product": 1304,
      "id_supplier": 0,
      "id_manufacturer": 89,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 300,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125035,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:52:53",
      "date_upd": "2014-03-16 08:58:44"
    },
    {
      "id_product": 1305,
      "id_supplier": 0,
      "id_manufacturer": 89,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 420,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125036,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 10:57:12",
      "date_upd": "2014-03-16 08:59:30"
    },
    {
      "id_product": 1306,
      "id_supplier": 0,
      "id_manufacturer": 90,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 420,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125040,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:01:50",
      "date_upd": "2014-03-16 09:00:27"
    },
    {
      "id_product": 1307,
      "id_supplier": 0,
      "id_manufacturer": 91,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 420,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125060,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:05:24",
      "date_upd": "2014-03-16 09:01:20"
    },
    {
      "id_product": 1308,
      "id_supplier": 0,
      "id_manufacturer": 88,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 420,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125150,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:11:46",
      "date_upd": "2014-03-16 09:02:17"
    },
    {
      "id_product": 1309,
      "id_supplier": 0,
      "id_manufacturer": 88,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 420,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125151,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:14:00",
      "date_upd": "2014-03-16 09:03:07"
    },
    {
      "id_product": 1310,
      "id_supplier": 0,
      "id_manufacturer": 88,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 320,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125105,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:16:17",
      "date_upd": "2014-03-16 09:04:07"
    },
    {
      "id_product": 1311,
      "id_supplier": 0,
      "id_manufacturer": 92,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 320,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125105,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:19:48",
      "date_upd": "2014-03-16 09:05:27"
    },
    {
      "id_product": 1312,
      "id_supplier": 0,
      "id_manufacturer": 93,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125103,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:21:59",
      "date_upd": "2014-03-16 09:06:20"
    },
    {
      "id_product": 1313,
      "id_supplier": 0,
      "id_manufacturer": 94,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125260,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:24:35",
      "date_upd": "2014-03-16 09:07:06"
    },
    {
      "id_product": 1314,
      "id_supplier": 0,
      "id_manufacturer": 95,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 920,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125164,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:27:56",
      "date_upd": "2014-03-16 09:08:17"
    },
    {
      "id_product": 1315,
      "id_supplier": 0,
      "id_manufacturer": 95,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 920,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125263,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:29:31",
      "date_upd": "2014-03-16 09:09:09"
    },
    {
      "id_product": 1316,
      "id_supplier": 0,
      "id_manufacturer": 95,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125166,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:31:27",
      "date_upd": "2014-03-16 09:10:00"
    },
    {
      "id_product": 1317,
      "id_supplier": 0,
      "id_manufacturer": 95,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 880,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125165,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:33:26",
      "date_upd": "2014-03-16 09:12:37"
    },
    {
      "id_product": 1318,
      "id_supplier": 0,
      "id_manufacturer": 96,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125104,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:35:53",
      "date_upd": "2014-03-16 09:13:33"
    },
    {
      "id_product": 1319,
      "id_supplier": 0,
      "id_manufacturer": 96,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125102,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:38:12",
      "date_upd": "2014-03-16 09:14:35"
    },
    {
      "id_product": 1320,
      "id_supplier": 0,
      "id_manufacturer": 98,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 410,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125160,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:40:14",
      "date_upd": "2014-03-16 09:15:45"
    },
    {
      "id_product": 1321,
      "id_supplier": 0,
      "id_manufacturer": 98,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125161,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:41:58",
      "date_upd": "2014-03-16 09:16:33"
    },
    {
      "id_product": 1322,
      "id_supplier": 0,
      "id_manufacturer": 98,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 700,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125159,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:44:23",
      "date_upd": "2014-03-16 09:17:42"
    },
    {
      "id_product": 1323,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 440,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 124998,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:48:03",
      "date_upd": "2014-03-16 09:18:43"
    },
    {
      "id_product": 1324,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 740,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125232,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:51:12",
      "date_upd": "2014-03-16 09:19:40"
    },
    {
      "id_product": 1325,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1100,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125233,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:53:26",
      "date_upd": "2014-03-16 09:20:37"
    },
    {
      "id_product": 1326,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 680,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125234,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:55:16",
      "date_upd": "2014-03-16 09:22:31"
    },
    {
      "id_product": 1327,
      "id_supplier": 0,
      "id_manufacturer": 99,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 690,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125037,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 11:57:28",
      "date_upd": "2014-03-16 09:23:41"
    },
    {
      "id_product": 1328,
      "id_supplier": 0,
      "id_manufacturer": 101,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 780,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-14",
      "reduction_to": "2014-03-14",
      "reference": 125050,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-14 12:01:35",
      "date_upd": "2014-03-16 09:24:32"
    },
    {
      "id_product": 1329,
      "id_supplier": 0,
      "id_manufacturer": 102,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125162,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:03:02",
      "date_upd": "2014-03-18 07:12:33"
    },
    {
      "id_product": 1330,
      "id_supplier": 0,
      "id_manufacturer": 103,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 287.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125163,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:05:31",
      "date_upd": "2014-03-18 07:14:35"
    },
    {
      "id_product": 1331,
      "id_supplier": 0,
      "id_manufacturer": 104,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 437,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125044,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:07:38",
      "date_upd": "2014-03-18 07:15:53"
    },
    {
      "id_product": 1332,
      "id_supplier": 0,
      "id_manufacturer": 104,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 522.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125045,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:09:14",
      "date_upd": "2014-03-18 07:16:50"
    },
    {
      "id_product": 1333,
      "id_supplier": 0,
      "id_manufacturer": 105,
      "id_tax": 3,
      "id_category_default": 12,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 124995,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:11:16",
      "date_upd": "2014-03-18 07:17:33"
    },
    {
      "id_product": 1334,
      "id_supplier": 0,
      "id_manufacturer": 105,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 198.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": "018970",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:13:46",
      "date_upd": "2014-03-18 07:19:12"
    },
    {
      "id_product": 1335,
      "id_supplier": 0,
      "id_manufacturer": 106,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 234.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125230,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:16:48",
      "date_upd": "2014-03-19 07:58:03"
    },
    {
      "id_product": 1336,
      "id_supplier": 0,
      "id_manufacturer": 106,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 198.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125235,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:19:17",
      "date_upd": "2014-03-19 08:00:09"
    },
    {
      "id_product": 1337,
      "id_supplier": 0,
      "id_manufacturer": 109,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 381.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125300,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:25:37",
      "date_upd": "2014-03-19 08:01:27"
    },
    {
      "id_product": 1338,
      "id_supplier": 0,
      "id_manufacturer": 109,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 381.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125302,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:28:14",
      "date_upd": "2014-03-19 08:05:10"
    },
    {
      "id_product": 1339,
      "id_supplier": 0,
      "id_manufacturer": 109,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 381.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125304,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:30:40",
      "date_upd": "2014-03-19 08:06:25"
    },
    {
      "id_product": 1340,
      "id_supplier": 0,
      "id_manufacturer": 109,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 381.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125306,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:32:52",
      "date_upd": "2014-03-19 08:07:31"
    },
    {
      "id_product": 1341,
      "id_supplier": 0,
      "id_manufacturer": 110,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125264,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:35:02",
      "date_upd": "2014-03-19 08:08:42"
    },
    {
      "id_product": 1342,
      "id_supplier": 0,
      "id_manufacturer": 108,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125267,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:37:13",
      "date_upd": "2014-03-19 08:10:23"
    },
    {
      "id_product": 1343,
      "id_supplier": 0,
      "id_manufacturer": 108,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125268,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:38:57",
      "date_upd": "2014-03-19 08:11:37"
    },
    {
      "id_product": 1344,
      "id_supplier": 0,
      "id_manufacturer": 111,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 202.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125271,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:42:15",
      "date_upd": "2014-03-19 08:13:30"
    },
    {
      "id_product": 1345,
      "id_supplier": 0,
      "id_manufacturer": 111,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 229.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125111,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:46:55",
      "date_upd": "2014-03-19 08:14:34"
    },
    {
      "id_product": 1346,
      "id_supplier": 0,
      "id_manufacturer": 111,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 223.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125266,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:49:03",
      "date_upd": "2014-03-19 08:15:48"
    },
    {
      "id_product": 1347,
      "id_supplier": 0,
      "id_manufacturer": 101,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 218.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125272,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:52:00",
      "date_upd": "2014-03-19 08:16:54"
    },
    {
      "id_product": 1348,
      "id_supplier": 0,
      "id_manufacturer": 112,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 367.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125273,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:55:13",
      "date_upd": "2014-03-19 08:18:07"
    },
    {
      "id_product": 1349,
      "id_supplier": 0,
      "id_manufacturer": 108,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 223.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125274,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 10:57:14",
      "date_upd": "2014-03-19 08:19:08"
    },
    {
      "id_product": 1350,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 22,
      "price": 550,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125220,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:02:02",
      "date_upd": "2020-11-23 10:33:51"
    },
    {
      "id_product": 1351,
      "id_supplier": 0,
      "id_manufacturer": 113,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 479.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125578,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:05:10",
      "date_upd": "2014-03-19 08:21:27"
    },
    {
      "id_product": 1352,
      "id_supplier": 0,
      "id_manufacturer": 114,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1839,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 125516,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:10:20",
      "date_upd": "2014-03-19 08:22:34"
    },
    {
      "id_product": 1353,
      "id_supplier": 0,
      "id_manufacturer": 115,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 399.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 124600,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:13:29",
      "date_upd": "2014-03-19 08:23:39"
    },
    {
      "id_product": 1354,
      "id_supplier": 0,
      "id_manufacturer": 115,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 445,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 124601,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:15:34",
      "date_upd": "2014-03-19 08:25:08"
    },
    {
      "id_product": 1355,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 665,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 131465,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:23:28",
      "date_upd": "2014-03-18 15:50:35"
    },
    {
      "id_product": 1356,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 408,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-17",
      "reduction_to": "2014-03-17",
      "reference": 130379,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-17 11:30:30",
      "date_upd": "2014-03-18 15:51:54"
    },
    {
      "id_product": 1357,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 1020,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130258,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:01:28",
      "date_upd": "2014-04-04 15:46:02"
    },
    {
      "id_product": 1358,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 330,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130260,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:04:47",
      "date_upd": "2017-11-27 10:16:11"
    },
    {
      "id_product": 1359,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 495.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130262,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:07:04",
      "date_upd": "2014-03-18 10:07:29"
    },
    {
      "id_product": 1360,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 69.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 122706,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:25:44",
      "date_upd": "2014-03-18 10:26:30"
    },
    {
      "id_product": 1361,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 57.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 122755,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:29:05",
      "date_upd": "2014-03-18 10:29:33"
    },
    {
      "id_product": 1362,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 95.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 122758,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:32:23",
      "date_upd": "2014-03-18 10:32:57"
    },
    {
      "id_product": 1363,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1219,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130526,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:35:46",
      "date_upd": "2014-03-18 10:36:21"
    },
    {
      "id_product": 1364,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 799,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130528,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:38:08",
      "date_upd": "2014-03-18 10:38:42"
    },
    {
      "id_product": 1365,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 826,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130530,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:40:37",
      "date_upd": "2014-03-18 10:41:04"
    },
    {
      "id_product": 1366,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 907.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130154,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:42:50",
      "date_upd": "2014-03-18 10:43:32"
    },
    {
      "id_product": 1367,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 481.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130156,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:45:26",
      "date_upd": "2014-03-18 10:45:57"
    },
    {
      "id_product": 1368,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 2,
      "price": 260,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 131184,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:47:59",
      "date_upd": "2016-08-19 14:50:02"
    },
    {
      "id_product": 1369,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 589.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130242,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:50:51",
      "date_upd": "2014-03-18 10:51:20"
    },
    {
      "id_product": 1370,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 130,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130218,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 10:54:44",
      "date_upd": "2014-03-18 15:53:14"
    },
    {
      "id_product": 1371,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2715.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130060,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:17:58",
      "date_upd": "2014-03-18 11:35:07"
    },
    {
      "id_product": 1372,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 937.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130064,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:21:24",
      "date_upd": "2014-03-18 11:38:58"
    },
    {
      "id_product": 1373,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1110.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130065,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:23:34",
      "date_upd": "2014-03-18 11:40:46"
    },
    {
      "id_product": 1374,
      "id_supplier": 0,
      "id_manufacturer": 117,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1359.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130066,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:25:31",
      "date_upd": "2014-03-18 11:43:17"
    },
    {
      "id_product": 1375,
      "id_supplier": 0,
      "id_manufacturer": 118,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 837.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130766,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:32:54",
      "date_upd": "2014-03-18 11:34:02"
    },
    {
      "id_product": 1376,
      "id_supplier": 0,
      "id_manufacturer": 118,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 851.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130765,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:46:03",
      "date_upd": "2014-03-18 11:46:44"
    },
    {
      "id_product": 1377,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2349.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130018,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 11:57:45",
      "date_upd": "2014-03-18 11:59:08"
    },
    {
      "id_product": 1378,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1285.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130016,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:04:47",
      "date_upd": "2014-03-18 12:05:23"
    },
    {
      "id_product": 1379,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 6192,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130012,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:07:55",
      "date_upd": "2014-03-18 15:42:10"
    },
    {
      "id_product": 1380,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 0,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 8937,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130010,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:10:46",
      "date_upd": "2014-03-18 15:43:10"
    },
    {
      "id_product": 1381,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2837.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130006,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:14:27",
      "date_upd": "2014-03-18 12:19:46"
    },
    {
      "id_product": 1382,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2837.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130004,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:17:13",
      "date_upd": "2014-03-18 12:17:44"
    },
    {
      "id_product": 1383,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 7680,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130008,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:21:54",
      "date_upd": "2014-03-18 15:44:20"
    },
    {
      "id_product": 1384,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 8488,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130000,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:24:17",
      "date_upd": "2014-03-18 15:45:19"
    },
    {
      "id_product": 1385,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 4,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 13995,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130002,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:27:09",
      "date_upd": "2014-03-18 15:46:08"
    },
    {
      "id_product": 1386,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1598.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130028,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:30:30",
      "date_upd": "2014-03-18 12:31:02"
    },
    {
      "id_product": 1387,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 959.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130030,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:32:39",
      "date_upd": "2014-03-18 12:33:05"
    },
    {
      "id_product": 1388,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 0,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 13035,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130038,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:35:23",
      "date_upd": "2014-03-18 15:46:56"
    },
    {
      "id_product": 1389,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 6137,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130040,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:38:50",
      "date_upd": "2014-03-18 15:47:45"
    },
    {
      "id_product": 1390,
      "id_supplier": 0,
      "id_manufacturer": 120,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4680,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-18",
      "reduction_to": "2014-03-18",
      "reference": 130032,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-18 12:41:31",
      "date_upd": "2014-03-18 15:48:39"
    },
    {
      "id_product": 1391,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 682.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130540,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 09:28:38",
      "date_upd": "2014-03-19 10:35:52"
    },
    {
      "id_product": 1392,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 879.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130542,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 09:31:08",
      "date_upd": "2014-03-19 10:37:04"
    },
    {
      "id_product": 1393,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 265,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131403,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:10:43",
      "date_upd": "2014-03-19 10:33:24"
    },
    {
      "id_product": 1394,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 521.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131404,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:13:25",
      "date_upd": "2014-03-19 10:34:48"
    },
    {
      "id_product": 1395,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 437,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130767,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:25:18",
      "date_upd": "2014-03-19 10:38:01"
    },
    {
      "id_product": 1396,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1465.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130751,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:28:24",
      "date_upd": "2014-03-19 10:38:58"
    },
    {
      "id_product": 1397,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 511.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130771,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:31:54",
      "date_upd": "2014-03-19 10:32:21"
    },
    {
      "id_product": 1398,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 772.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130769,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:41:54",
      "date_upd": "2014-03-19 10:42:15"
    },
    {
      "id_product": 1399,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 307.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131816,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:47:48",
      "date_upd": "2014-03-19 10:52:35"
    },
    {
      "id_product": 1400,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 639.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131826,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:50:37",
      "date_upd": "2014-03-19 10:51:23"
    },
    {
      "id_product": 1403,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 299.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131820,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:00:24",
      "date_upd": "2014-03-19 11:00:50"
    },
    {
      "id_product": 1402,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 256.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131818,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 10:56:37",
      "date_upd": "2014-03-19 10:56:59"
    },
    {
      "id_product": 1404,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 4,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1458.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130770,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:03:11",
      "date_upd": "2014-03-19 11:03:44"
    },
    {
      "id_product": 1405,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 772.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130772,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:05:58",
      "date_upd": "2014-03-19 11:06:18"
    },
    {
      "id_product": 1406,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 639.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131827,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:08:18",
      "date_upd": "2014-03-19 11:08:43"
    },
    {
      "id_product": 1407,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 772.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130753,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:10:27",
      "date_upd": "2014-03-19 11:11:04"
    },
    {
      "id_product": 1408,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 799.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130746,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:13:19",
      "date_upd": "2014-03-19 11:13:42"
    },
    {
      "id_product": 1409,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 596.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131828,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:15:47",
      "date_upd": "2014-03-19 11:16:11"
    },
    {
      "id_product": 1410,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131810,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:18:12",
      "date_upd": "2014-03-19 11:18:36"
    },
    {
      "id_product": 1411,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 556.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131829,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:21:15",
      "date_upd": "2014-03-19 11:22:43"
    },
    {
      "id_product": 1412,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 719.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130752,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:25:00",
      "date_upd": "2014-03-19 11:25:27"
    },
    {
      "id_product": 1413,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 676.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131830,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:27:34",
      "date_upd": "2014-03-19 11:27:59"
    },
    {
      "id_product": 1414,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 889.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130755,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:29:52",
      "date_upd": "2014-03-19 11:30:27"
    },
    {
      "id_product": 1415,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 490.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131812,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:33:22",
      "date_upd": "2014-03-19 11:33:56"
    },
    {
      "id_product": 1416,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 719.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131825,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:35:41",
      "date_upd": "2014-03-19 11:36:05"
    },
    {
      "id_product": 1417,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 575.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131825,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:38:38",
      "date_upd": "2014-03-19 11:39:04"
    },
    {
      "id_product": 1418,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 676.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131831,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:40:56",
      "date_upd": "2014-03-19 11:41:27"
    },
    {
      "id_product": 1419,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 350,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130764,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:45:19",
      "date_upd": "2014-03-19 15:34:10"
    },
    {
      "id_product": 1420,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 286.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130747,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:48:41",
      "date_upd": "2014-03-19 11:49:07"
    },
    {
      "id_product": 1421,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 265,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130749,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:51:55",
      "date_upd": "2014-03-19 11:52:17"
    },
    {
      "id_product": 1422,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1012.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130748,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:54:40",
      "date_upd": "2014-03-19 11:55:03"
    },
    {
      "id_product": 1423,
      "id_supplier": 0,
      "id_manufacturer": 69,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 486.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130750,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 11:57:26",
      "date_upd": "2014-03-19 11:57:46"
    },
    {
      "id_product": 1424,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 419.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130191,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:16:18",
      "date_upd": "2014-03-19 12:16:44"
    },
    {
      "id_product": 1425,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 705,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131822,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:20:04",
      "date_upd": "2014-03-19 12:20:37"
    },
    {
      "id_product": 1426,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 103.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130218,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:23:14",
      "date_upd": "2014-03-19 12:23:37"
    },
    {
      "id_product": 1427,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 103.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130222,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:26:27",
      "date_upd": "2014-03-19 12:26:50"
    },
    {
      "id_product": 1428,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 543.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 131821,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:30:21",
      "date_upd": "2014-03-19 12:30:48"
    },
    {
      "id_product": 1429,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 543.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130593,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:33:45",
      "date_upd": "2014-03-19 12:34:06"
    },
    {
      "id_product": 1430,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 0,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130001,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:36:21",
      "date_upd": "2014-03-19 12:37:06"
    },
    {
      "id_product": 1431,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 0,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130003,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:39:46",
      "date_upd": "2014-03-19 12:40:06"
    },
    {
      "id_product": 1432,
      "id_supplier": 0,
      "id_manufacturer": 121,
      "id_tax": 0,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-19",
      "reduction_to": "2014-03-19",
      "reference": 130005,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-19 12:42:18",
      "date_upd": "2014-03-19 12:42:37"
    },
    {
      "id_product": 1433,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2121.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125275,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 08:43:08",
      "date_upd": "2014-03-20 08:43:34"
    },
    {
      "id_product": 1434,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 2121.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125276,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 08:45:13",
      "date_upd": "2015-05-29 14:39:22"
    },
    {
      "id_product": 1435,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 2121.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125279,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 08:46:57",
      "date_upd": "2015-05-29 14:39:48"
    },
    {
      "id_product": 1436,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 2121.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125277,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 08:49:13",
      "date_upd": "2015-05-29 14:38:23"
    },
    {
      "id_product": 1437,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 639.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131837,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:06:16",
      "date_upd": "2014-03-20 11:06:43"
    },
    {
      "id_product": 1438,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 543.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131821,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:12:41",
      "date_upd": "2014-03-20 11:13:09"
    },
    {
      "id_product": 1439,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 290.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130591,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:14:47",
      "date_upd": "2014-03-20 11:15:08"
    },
    {
      "id_product": 1440,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 543.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130593,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:17:23",
      "date_upd": "2014-03-20 11:17:43"
    },
    {
      "id_product": 1441,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 386.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130597,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:19:32",
      "date_upd": "2014-03-20 11:19:58"
    },
    {
      "id_product": 1442,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 709.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130595,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:22:14",
      "date_upd": "2014-03-20 11:22:39"
    },
    {
      "id_product": 1443,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 559.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131838,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:24:39",
      "date_upd": "2014-03-20 11:25:02"
    },
    {
      "id_product": 1444,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 495.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130773,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:27:13",
      "date_upd": "2014-03-20 11:27:36"
    },
    {
      "id_product": 1445,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 315.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131840,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:30:30",
      "date_upd": "2014-03-20 11:30:58"
    },
    {
      "id_product": 1446,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 604.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131840,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:33:15",
      "date_upd": "2014-03-20 11:33:36"
    },
    {
      "id_product": 1447,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 887.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130042,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:36:16",
      "date_upd": "2014-03-20 11:36:39"
    },
    {
      "id_product": 1448,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 952,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130041,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:39:24",
      "date_upd": "2014-03-20 11:39:53"
    },
    {
      "id_product": 1449,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1822.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131841,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:42:32",
      "date_upd": "2014-03-20 11:43:03"
    },
    {
      "id_product": 1450,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1047.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130047,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:45:17",
      "date_upd": "2014-03-20 11:45:38"
    },
    {
      "id_product": 1451,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 879.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131842,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:47:42",
      "date_upd": "2014-03-20 11:48:08"
    },
    {
      "id_product": 1452,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 444.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 130045,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:49:56",
      "date_upd": "2014-03-20 11:50:28"
    },
    {
      "id_product": 1453,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 879.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 131843,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 11:52:28",
      "date_upd": "2014-03-20 11:55:30"
    },
    {
      "id_product": 1454,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 811.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125512,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:31:25",
      "date_upd": "2014-03-20 12:32:19"
    },
    {
      "id_product": 1455,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 959,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125513,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:34:03",
      "date_upd": "2014-03-20 12:34:24"
    },
    {
      "id_product": 1456,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 719.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125490,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:37:43",
      "date_upd": "2014-03-20 12:38:34"
    },
    {
      "id_product": 1457,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 589.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125495,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:42:19",
      "date_upd": "2014-03-20 12:42:48"
    },
    {
      "id_product": 1458,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 912.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125524,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:45:04",
      "date_upd": "2014-03-20 12:45:27"
    },
    {
      "id_product": 1459,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 266.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125526,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:47:38",
      "date_upd": "2014-03-20 12:48:04"
    },
    {
      "id_product": 1460,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 277,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125570,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:49:28",
      "date_upd": "2014-03-20 12:49:54"
    },
    {
      "id_product": 1461,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 234.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125572,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:51:40",
      "date_upd": "2014-03-20 12:52:12"
    },
    {
      "id_product": 1462,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 346.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125576,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:53:53",
      "date_upd": "2014-03-20 12:54:30"
    },
    {
      "id_product": 1463,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 517.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 122416,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:56:41",
      "date_upd": "2014-03-20 12:57:04"
    },
    {
      "id_product": 1464,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 386.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125564,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 12:58:40",
      "date_upd": "2014-03-20 12:59:04"
    },
    {
      "id_product": 1465,
      "id_supplier": 0,
      "id_manufacturer": 116,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 435.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125529,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 13:01:40",
      "date_upd": "2014-03-20 13:02:06"
    },
    {
      "id_product": 1466,
      "id_supplier": 0,
      "id_manufacturer": 116,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1092.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-20",
      "reduction_to": "2014-03-20",
      "reference": 125505,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-20 13:03:55",
      "date_upd": "2014-03-20 13:04:20"
    },
    {
      "id_product": 1467,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 522.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 130763,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:38:14",
      "date_upd": "2014-03-21 10:42:16"
    },
    {
      "id_product": 1468,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 795.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125544,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:40:57",
      "date_upd": "2014-03-21 10:41:27"
    },
    {
      "id_product": 1469,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 636.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125546,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:45:24",
      "date_upd": "2014-03-21 10:45:38"
    },
    {
      "id_product": 1470,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 410.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125517,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:47:56",
      "date_upd": "2014-03-21 10:48:59"
    },
    {
      "id_product": 1471,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 245.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112704,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:51:40",
      "date_upd": "2014-03-21 10:52:03"
    },
    {
      "id_product": 1472,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 805.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112770,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:53:52",
      "date_upd": "2014-03-21 10:54:18"
    },
    {
      "id_product": 1473,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 277,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112710,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:56:18",
      "date_upd": "2014-03-21 10:56:40"
    },
    {
      "id_product": 1474,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 298.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112718,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 10:58:17",
      "date_upd": "2014-03-21 10:58:40"
    },
    {
      "id_product": 1475,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 239.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112712,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:00:37",
      "date_upd": "2014-03-21 11:01:00"
    },
    {
      "id_product": 1476,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 239.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112726,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:02:41",
      "date_upd": "2014-03-21 11:03:10"
    },
    {
      "id_product": 1477,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 1,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1200.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125597,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:05:04",
      "date_upd": "2015-07-02 12:59:17"
    },
    {
      "id_product": 1478,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 362.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112758,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:08:58",
      "date_upd": "2014-03-21 11:09:19"
    },
    {
      "id_product": 1479,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 351.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112760,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:11:01",
      "date_upd": "2014-03-21 11:11:25"
    },
    {
      "id_product": 1480,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 346.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125584,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:13:09",
      "date_upd": "2014-03-21 11:13:35"
    },
    {
      "id_product": 1481,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 277,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112756,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:15:33",
      "date_upd": "2014-03-21 11:15:56"
    },
    {
      "id_product": 1482,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1245.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 130467,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:17:59",
      "date_upd": "2014-03-21 11:18:21"
    },
    {
      "id_product": 1483,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1113.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125509,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:20:18",
      "date_upd": "2014-03-21 11:21:02"
    },
    {
      "id_product": 1484,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1750.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125511,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:22:46",
      "date_upd": "2014-03-21 11:23:04"
    },
    {
      "id_product": 1485,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 410,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112764,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:25:16",
      "date_upd": "2020-09-23 14:05:49"
    },
    {
      "id_product": 1486,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 192,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112610,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:30:08",
      "date_upd": "2014-03-21 11:30:30"
    },
    {
      "id_product": 1487,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1066.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 112900,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:32:22",
      "date_upd": "2014-03-21 11:33:28"
    },
    {
      "id_product": 1488,
      "id_supplier": 0,
      "id_manufacturer": 119,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 789.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125520,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:35:53",
      "date_upd": "2014-03-21 11:36:22"
    },
    {
      "id_product": 1489,
      "id_supplier": 0,
      "id_manufacturer": 119,
      "id_tax": 0,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125519,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:37:45",
      "date_upd": "2014-03-21 11:38:16"
    },
    {
      "id_product": 1490,
      "id_supplier": 0,
      "id_manufacturer": 119,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 406.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125518,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:40:09",
      "date_upd": "2014-03-21 11:40:41"
    },
    {
      "id_product": 1491,
      "id_supplier": 0,
      "id_manufacturer": 119,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 367.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125500,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:42:35",
      "date_upd": "2014-03-21 11:43:03"
    },
    {
      "id_product": 1492,
      "id_supplier": 0,
      "id_manufacturer": 119,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 575.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125510,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:44:34",
      "date_upd": "2014-03-21 11:44:58"
    },
    {
      "id_product": 1493,
      "id_supplier": 0,
      "id_manufacturer": 119,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1196.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": 125502,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 11:46:45",
      "date_upd": "2014-03-21 11:47:05"
    },
    {
      "id_product": 1494,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 16.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011020",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:39:24",
      "date_upd": "2014-03-21 12:39:43"
    },
    {
      "id_product": 1495,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011040",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:41:06",
      "date_upd": "2014-03-21 12:41:26"
    },
    {
      "id_product": 1496,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011045",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:43:21",
      "date_upd": "2014-03-21 12:43:51"
    },
    {
      "id_product": 1497,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011060",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:45:57",
      "date_upd": "2014-03-21 12:46:16"
    },
    {
      "id_product": 1498,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011080",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:47:47",
      "date_upd": "2014-03-21 12:48:11"
    },
    {
      "id_product": 1499,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 16.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011120",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:50:02",
      "date_upd": "2014-03-21 12:50:22"
    },
    {
      "id_product": 1500,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011125",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:51:49",
      "date_upd": "2014-03-21 12:52:09"
    },
    {
      "id_product": 1501,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 16.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011140",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:53:39",
      "date_upd": "2014-03-21 12:53:56"
    },
    {
      "id_product": 1502,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 0,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011160",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:55:27",
      "date_upd": "2014-03-21 12:55:56"
    },
    {
      "id_product": 1503,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 16.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011170",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:57:45",
      "date_upd": "2014-03-21 12:58:05"
    },
    {
      "id_product": 1504,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 16.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011220",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 12:59:54",
      "date_upd": "2014-03-21 13:00:16"
    },
    {
      "id_product": 1505,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 16.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011230",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 13:01:47",
      "date_upd": "2014-03-21 13:02:07"
    },
    {
      "id_product": 1506,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 0,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 0,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011320",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 13:03:54",
      "date_upd": "2014-03-21 13:04:14"
    },
    {
      "id_product": 1507,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 44,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-21",
      "reduction_to": "2014-03-21",
      "reference": "011341",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-21 13:06:15",
      "date_upd": "2014-03-21 13:06:37"
    },
    {
      "id_product": 1508,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130152,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 10:47:30",
      "date_upd": "2014-03-26 15:25:41"
    },
    {
      "id_product": 1509,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 74.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130202,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 10:51:45",
      "date_upd": "2014-03-25 10:52:08"
    },
    {
      "id_product": 1510,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 181.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130103,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 10:55:09",
      "date_upd": "2014-03-25 10:55:34"
    },
    {
      "id_product": 1511,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 76.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130205,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 10:58:34",
      "date_upd": "2014-03-25 10:58:52"
    },
    {
      "id_product": 1512,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 280,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130097,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:07:04",
      "date_upd": "2023-09-06 12:23:18"
    },
    {
      "id_product": 1513,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 366.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130199,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:09:41",
      "date_upd": "2014-03-25 11:10:02"
    },
    {
      "id_product": 1514,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 174.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130007,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:19:56",
      "date_upd": "2014-03-25 11:20:14"
    },
    {
      "id_product": 1515,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1101.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130124,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:23:20",
      "date_upd": "2014-03-25 11:23:46"
    },
    {
      "id_product": 1516,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 321,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130193,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:26:55",
      "date_upd": "2014-03-25 11:27:15"
    },
    {
      "id_product": 1517,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 104.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130209,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:30:46",
      "date_upd": "2014-03-25 11:31:06"
    },
    {
      "id_product": 1518,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 162.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130227,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:34:11",
      "date_upd": "2014-03-25 11:34:31"
    },
    {
      "id_product": 1519,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 301.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130192,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:36:44",
      "date_upd": "2014-03-25 11:37:12"
    },
    {
      "id_product": 1520,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 129.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130214,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:40:38",
      "date_upd": "2014-03-25 11:41:23"
    },
    {
      "id_product": 1521,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 309.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130358,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:44:23",
      "date_upd": "2014-03-25 11:44:48"
    },
    {
      "id_product": 1522,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 228,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130191,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:46:47",
      "date_upd": "2014-03-25 12:56:19"
    },
    {
      "id_product": 1523,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 59.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130217,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:51:01",
      "date_upd": "2014-03-25 11:51:40"
    },
    {
      "id_product": 1524,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 103.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130218,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:54:31",
      "date_upd": "2014-03-25 11:54:51"
    },
    {
      "id_product": 1525,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 68,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130223,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 11:57:50",
      "date_upd": "2014-03-25 12:00:04"
    },
    {
      "id_product": 1526,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 103.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130222,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:17:05",
      "date_upd": "2014-03-25 12:17:33"
    },
    {
      "id_product": 1527,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 114.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 131920,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:20:24",
      "date_upd": "2014-03-25 12:21:47"
    },
    {
      "id_product": 1528,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 410.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130196,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:24:05",
      "date_upd": "2014-03-25 12:24:23"
    },
    {
      "id_product": 1529,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 799.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130197,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:27:22",
      "date_upd": "2014-03-25 12:28:12"
    },
    {
      "id_product": 1530,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 287.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130224,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:31:19",
      "date_upd": "2014-03-25 12:31:40"
    },
    {
      "id_product": 1531,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 401.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130194,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:34:15",
      "date_upd": "2014-03-25 12:34:34"
    },
    {
      "id_product": 1532,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 495.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130195,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:38:01",
      "date_upd": "2014-03-25 12:38:34"
    },
    {
      "id_product": 1533,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 132.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 131767,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:41:14",
      "date_upd": "2014-03-25 12:41:34"
    },
    {
      "id_product": 1534,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 89.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130090,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:46:23",
      "date_upd": "2014-03-25 12:47:25"
    },
    {
      "id_product": 1535,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 70.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-25",
      "reduction_to": "2014-03-25",
      "reference": 130213,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-25 12:50:23",
      "date_upd": "2014-03-25 12:50:57"
    },
    {
      "id_product": 1536,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 847.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130590,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 09:57:59",
      "date_upd": "2014-03-26 09:58:27"
    },
    {
      "id_product": 1537,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1194.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130233,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:00:51",
      "date_upd": "2014-03-26 10:01:16"
    },
    {
      "id_product": 1538,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 732.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130508,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:03:43",
      "date_upd": "2014-03-26 10:04:07"
    },
    {
      "id_product": 1539,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 442.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131760,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:07:26",
      "date_upd": "2014-03-26 10:07:47"
    },
    {
      "id_product": 1540,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 835.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130231,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:09:49",
      "date_upd": "2014-03-26 10:10:13"
    },
    {
      "id_product": 1541,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 281.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131762,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:12:23",
      "date_upd": "2014-03-26 10:13:26"
    },
    {
      "id_product": 1542,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 502.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131736,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:16:02",
      "date_upd": "2014-03-26 10:16:51"
    },
    {
      "id_product": 1543,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1332.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131764,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:19:02",
      "date_upd": "2014-03-26 10:19:22"
    },
    {
      "id_product": 1544,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 354.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131765,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:21:56",
      "date_upd": "2014-03-26 10:22:22"
    },
    {
      "id_product": 1545,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 307.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131766,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:24:33",
      "date_upd": "2014-03-26 10:24:55"
    },
    {
      "id_product": 1546,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 811.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130226,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:27:12",
      "date_upd": "2014-03-26 10:27:33"
    },
    {
      "id_product": 1547,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 290.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130235,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:30:07",
      "date_upd": "2014-03-26 10:30:33"
    },
    {
      "id_product": 1548,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 723,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130228,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:32:50",
      "date_upd": "2014-03-26 10:33:10"
    },
    {
      "id_product": 1549,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 269,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130235,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:40:08",
      "date_upd": "2014-03-26 10:40:27"
    },
    {
      "id_product": 1550,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 632,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130237,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:43:26",
      "date_upd": "2014-03-26 10:43:52"
    },
    {
      "id_product": 1551,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 410.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130238,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:46:28",
      "date_upd": "2023-11-24 10:12:13"
    },
    {
      "id_product": 1552,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 958.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131266,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:49:48",
      "date_upd": "2014-03-26 10:50:10"
    },
    {
      "id_product": 1553,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 408,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130240,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:53:23",
      "date_upd": "2014-03-26 10:53:49"
    },
    {
      "id_product": 1554,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 461.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130232,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 10:56:42",
      "date_upd": "2014-03-26 10:57:33"
    },
    {
      "id_product": 1555,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1040.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130370,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:01:24",
      "date_upd": "2014-03-26 11:01:43"
    },
    {
      "id_product": 1556,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 294.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130673,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:05:25",
      "date_upd": "2014-03-26 11:07:21"
    },
    {
      "id_product": 1557,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 269,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130673,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:11:28",
      "date_upd": "2014-03-26 11:12:07"
    },
    {
      "id_product": 1558,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1040.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130656,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:14:37",
      "date_upd": "2014-03-26 11:15:49"
    },
    {
      "id_product": 1559,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 907.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130154,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:20:21",
      "date_upd": "2014-03-26 11:20:51"
    },
    {
      "id_product": 1560,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130661,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:22:55",
      "date_upd": "2023-02-01 10:01:37"
    },
    {
      "id_product": 1561,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 481.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130156,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:26:29",
      "date_upd": "2014-03-26 11:26:47"
    },
    {
      "id_product": 1562,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 513.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131184,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:29:20",
      "date_upd": "2014-03-26 11:29:39"
    },
    {
      "id_product": 1563,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 589.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130242,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:33:19",
      "date_upd": "2014-03-26 11:33:38"
    },
    {
      "id_product": 1564,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 566.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 131186,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:36:10",
      "date_upd": "2014-03-26 11:36:39"
    },
    {
      "id_product": 1565,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-26",
      "reduction_to": "2014-03-26",
      "reference": 130244,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-26 11:39:13",
      "date_upd": "2014-03-26 11:39:33"
    },
    {
      "id_product": 1566,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 959.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131461,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 09:55:16",
      "date_upd": "2014-03-27 09:55:48"
    },
    {
      "id_product": 1568,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 707.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131465,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:01:28",
      "date_upd": "2014-03-27 10:01:53"
    },
    {
      "id_product": 1569,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 522.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131459,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:04:35",
      "date_upd": "2014-03-27 10:04:55"
    },
    {
      "id_product": 1570,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 979.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131467,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:06:56",
      "date_upd": "2014-03-27 10:07:20"
    },
    {
      "id_product": 1571,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 760,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131460,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:10:09",
      "date_upd": "2020-01-24 08:26:34"
    },
    {
      "id_product": 1572,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 640.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131463,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:13:06",
      "date_upd": "2014-03-27 10:13:34"
    },
    {
      "id_product": 1573,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 651.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131257,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:16:46",
      "date_upd": "2014-03-27 10:17:21"
    },
    {
      "id_product": 1574,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 333.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131258,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:20:07",
      "date_upd": "2014-03-27 10:21:06"
    },
    {
      "id_product": 1575,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 401.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131251,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:23:40",
      "date_upd": "2014-03-27 10:24:00"
    },
    {
      "id_product": 1576,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1100,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130301,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:27:17",
      "date_upd": "2023-03-16 12:49:21"
    },
    {
      "id_product": 1577,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 727.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130300,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:32:36",
      "date_upd": "2014-03-27 10:33:06"
    },
    {
      "id_product": 1578,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1156.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131190,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:35:54",
      "date_upd": "2014-03-27 10:36:16"
    },
    {
      "id_product": 1579,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 352.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 132010,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:39:36",
      "date_upd": "2014-03-27 10:40:04"
    },
    {
      "id_product": 1580,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 913.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130304,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:43:54",
      "date_upd": "2014-03-27 10:44:35"
    },
    {
      "id_product": 1581,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 959.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131200,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:48:02",
      "date_upd": "2014-03-27 10:48:25"
    },
    {
      "id_product": 1582,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 384.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131202,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:51:09",
      "date_upd": "2014-03-27 10:51:30"
    },
    {
      "id_product": 1583,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 483.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131205,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:54:08",
      "date_upd": "2014-03-27 10:54:29"
    },
    {
      "id_product": 1584,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 820.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130290,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:57:02",
      "date_upd": "2014-03-27 10:57:29"
    },
    {
      "id_product": 1585,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 358.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130307,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 10:59:59",
      "date_upd": "2014-03-27 11:00:20"
    },
    {
      "id_product": 1586,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 639.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131204,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:03:22",
      "date_upd": "2014-03-27 11:03:41"
    },
    {
      "id_product": 1587,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 316,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130305,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:08:56",
      "date_upd": "2014-03-27 11:09:27"
    },
    {
      "id_product": 1588,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 390,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130306,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:12:07",
      "date_upd": "2017-11-21 14:32:47"
    },
    {
      "id_product": 1589,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 384.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130308,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:14:51",
      "date_upd": "2014-03-27 11:15:13"
    },
    {
      "id_product": 1590,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1752.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131206,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:18:06",
      "date_upd": "2014-03-27 11:18:25"
    },
    {
      "id_product": 1591,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 871.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131208,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:20:51",
      "date_upd": "2014-03-27 11:21:12"
    },
    {
      "id_product": 1592,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 666.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131215,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:23:32",
      "date_upd": "2014-03-27 11:23:56"
    },
    {
      "id_product": 1593,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 639.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131212,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:26:37",
      "date_upd": "2014-03-27 11:27:06"
    },
    {
      "id_product": 1594,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 666.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131214,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:29:45",
      "date_upd": "2014-03-27 11:30:17"
    },
    {
      "id_product": 1595,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 559.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130276,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:32:51",
      "date_upd": "2014-03-27 11:33:12"
    },
    {
      "id_product": 1596,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 326.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131210,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:35:46",
      "date_upd": "2014-03-27 11:36:13"
    },
    {
      "id_product": 1597,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 299.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131216,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:38:41",
      "date_upd": "2014-03-27 11:39:06"
    },
    {
      "id_product": 1598,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 358.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131218,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:41:49",
      "date_upd": "2014-03-27 11:42:15"
    },
    {
      "id_product": 1599,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 300.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130278,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:44:52",
      "date_upd": "2014-03-27 11:45:18"
    },
    {
      "id_product": 1600,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 333.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130400,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:47:45",
      "date_upd": "2014-03-27 11:48:07"
    },
    {
      "id_product": 1601,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 410.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130280,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:51:09",
      "date_upd": "2014-03-27 11:51:28"
    },
    {
      "id_product": 1602,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 639.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130402,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:54:45",
      "date_upd": "2014-03-27 11:55:14"
    },
    {
      "id_product": 1603,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1011.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131220,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:57:07",
      "date_upd": "2014-03-27 11:57:31"
    },
    {
      "id_product": 1604,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 522.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131222,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 11:59:31",
      "date_upd": "2014-03-27 11:59:53"
    },
    {
      "id_product": 1605,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1172.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130286,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:02:54",
      "date_upd": "2014-03-27 12:03:15"
    },
    {
      "id_product": 1606,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 772.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131224,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:05:25",
      "date_upd": "2014-03-27 12:05:55"
    },
    {
      "id_product": 1607,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 521.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130404,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:08:13",
      "date_upd": "2014-03-27 12:08:36"
    },
    {
      "id_product": 1608,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 916.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131230,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:12:27",
      "date_upd": "2014-03-27 12:12:56"
    },
    {
      "id_product": 1609,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 680,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131238,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:15:25",
      "date_upd": "2014-03-28 10:40:33"
    },
    {
      "id_product": 1610,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 861.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131240,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:17:48",
      "date_upd": "2014-03-27 12:18:10"
    },
    {
      "id_product": 1611,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 826,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131244,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:22:28",
      "date_upd": "2014-03-27 12:22:51"
    },
    {
      "id_product": 1612,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 544,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130412,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:30:37",
      "date_upd": "2014-03-27 12:31:06"
    },
    {
      "id_product": 1613,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 786.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131252,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:34:06",
      "date_upd": "2014-03-27 12:34:49"
    },
    {
      "id_product": 1614,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1137.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 131249,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:38:59",
      "date_upd": "2014-03-27 12:39:20"
    },
    {
      "id_product": 1615,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 959.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130274,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:50:08",
      "date_upd": "2014-03-27 12:50:47"
    },
    {
      "id_product": 1616,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 326.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130275,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:53:29",
      "date_upd": "2014-03-27 12:54:07"
    },
    {
      "id_product": 1617,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 462.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-27",
      "reduction_to": "2014-03-27",
      "reference": 130277,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-27 12:57:17",
      "date_upd": "2014-03-27 12:57:42"
    },
    {
      "id_product": 1618,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 348.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130282,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 09:46:53",
      "date_upd": "2014-03-28 09:47:33"
    },
    {
      "id_product": 1619,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 571.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130284,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 09:50:20",
      "date_upd": "2014-03-28 09:51:03"
    },
    {
      "id_product": 1620,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1062.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130288,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 09:53:02",
      "date_upd": "2014-03-28 09:53:30"
    },
    {
      "id_product": 1621,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 328.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130264,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 09:56:51",
      "date_upd": "2014-03-28 09:57:29"
    },
    {
      "id_product": 1622,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 228.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130267,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:00:35",
      "date_upd": "2014-03-28 10:00:56"
    },
    {
      "id_product": 1623,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 179.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130269,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:03:27",
      "date_upd": "2014-03-28 10:03:57"
    },
    {
      "id_product": 1624,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 427,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130259,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:06:21",
      "date_upd": "2014-03-28 10:06:47"
    },
    {
      "id_product": 1625,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 182.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130270,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:09:17",
      "date_upd": "2014-03-28 10:09:37"
    },
    {
      "id_product": 1626,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1108.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130295,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:11:45",
      "date_upd": "2014-03-28 10:12:08"
    },
    {
      "id_product": 1627,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 871.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130272,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:15:15",
      "date_upd": "2014-03-28 10:15:49"
    },
    {
      "id_product": 1628,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1386.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130291,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:18:47",
      "date_upd": "2014-03-28 10:19:11"
    },
    {
      "id_product": 1629,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 985.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130293,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:22:36",
      "date_upd": "2014-03-28 10:23:08"
    },
    {
      "id_product": 1630,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 952,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130296,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:25:25",
      "date_upd": "2014-03-28 10:25:54"
    },
    {
      "id_product": 1631,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1312,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130299,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:27:52",
      "date_upd": "2014-03-28 10:28:16"
    },
    {
      "id_product": 1632,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 598.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130292,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:32:09",
      "date_upd": "2014-03-28 10:32:32"
    },
    {
      "id_product": 1633,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2182.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130679,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:37:02",
      "date_upd": "2014-03-28 10:37:40"
    },
    {
      "id_product": 1634,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1467.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130298,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:40:54",
      "date_upd": "2014-03-28 10:41:17"
    },
    {
      "id_product": 1635,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 985.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-28",
      "reduction_to": "2014-03-28",
      "reference": 130294,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-28 10:43:44",
      "date_upd": "2014-03-28 10:44:09"
    },
    {
      "id_product": 1636,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1589.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130465,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 10:45:18",
      "date_upd": "2014-03-31 10:54:33"
    },
    {
      "id_product": 1637,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1455.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130466,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 10:49:35",
      "date_upd": "2014-03-31 10:50:03"
    },
    {
      "id_product": 1638,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 987.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130468,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 10:53:03",
      "date_upd": "2014-03-31 10:53:32"
    },
    {
      "id_product": 1639,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1541.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130470,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 10:57:38",
      "date_upd": "2014-03-31 10:58:12"
    },
    {
      "id_product": 1641,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 1247.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130432,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 0,
      "indexed": 1,
      "date_add": "2014-03-31 11:09:52",
      "date_upd": "2017-09-19 08:59:55"
    },
    {
      "id_product": 1642,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 966.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130434,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:13:12",
      "date_upd": "2014-03-31 11:13:36"
    },
    {
      "id_product": 1643,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 0,
      "price": 1600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130436,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:16:09",
      "date_upd": "2017-05-17 15:54:33"
    },
    {
      "id_product": 1644,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1066.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130454,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 0,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:20:33",
      "date_upd": "2023-04-17 14:25:47"
    },
    {
      "id_product": 1646,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 723.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130458,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:26:53",
      "date_upd": "2014-03-31 11:27:31"
    },
    {
      "id_product": 1647,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 102.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130692,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:30:46",
      "date_upd": "2014-03-31 11:31:14"
    },
    {
      "id_product": 1648,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 463.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130694,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:33:37",
      "date_upd": "2014-03-31 11:34:01"
    },
    {
      "id_product": 1649,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 68.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130712,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:37:52",
      "date_upd": "2014-03-31 11:38:24"
    },
    {
      "id_product": 1650,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 165.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130714,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:41:11",
      "date_upd": "2014-03-31 11:41:48"
    },
    {
      "id_product": 1651,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 155.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130716,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:44:23",
      "date_upd": "2014-03-31 11:44:47"
    },
    {
      "id_product": 1652,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 279.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130718,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 11:47:09",
      "date_upd": "2014-03-31 11:47:32"
    },
    {
      "id_product": 1653,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 427.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130730,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:00:41",
      "date_upd": "2014-03-31 12:01:06"
    },
    {
      "id_product": 1654,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 145.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130732,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:03:17",
      "date_upd": "2014-03-31 12:03:43"
    },
    {
      "id_product": 1655,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 278.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130736,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:05:43",
      "date_upd": "2014-03-31 12:06:04"
    },
    {
      "id_product": 1656,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 145.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130738,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:07:52",
      "date_upd": "2014-03-31 12:08:15"
    },
    {
      "id_product": 1657,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 971,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130495,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:11:41",
      "date_upd": "2014-03-31 12:12:11"
    },
    {
      "id_product": 1658,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 746.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130525,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:15:14",
      "date_upd": "2014-03-31 12:16:18"
    },
    {
      "id_product": 1659,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 922.3,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130513,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:19:10",
      "date_upd": "2014-03-31 12:19:36"
    },
    {
      "id_product": 1660,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 571.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130490,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:23:34",
      "date_upd": "2014-03-31 12:24:11"
    },
    {
      "id_product": 1661,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 376,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 130498,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:26:48",
      "date_upd": "2014-03-31 12:27:10"
    },
    {
      "id_product": 1662,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1651.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 131293,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:31:34",
      "date_upd": "2014-03-31 12:32:06"
    },
    {
      "id_product": 1663,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1891.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 131294,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:35:37",
      "date_upd": "2014-03-31 12:35:58"
    },
    {
      "id_product": 1664,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1891.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-03-31",
      "reduction_to": "2014-03-31",
      "reference": 131319,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-03-31 12:38:02",
      "date_upd": "2014-03-31 12:38:21"
    },
    {
      "id_product": 1665,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1265.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130481,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 11:56:45",
      "date_upd": "2014-04-01 11:57:31"
    },
    {
      "id_product": 1666,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 625.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 131410,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:05:54",
      "date_upd": "2014-04-01 12:06:19"
    },
    {
      "id_product": 1667,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 609.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 131412,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:08:48",
      "date_upd": "2014-04-01 12:09:27"
    },
    {
      "id_product": 1668,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 546.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130611,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:13:02",
      "date_upd": "2014-04-01 12:13:27"
    },
    {
      "id_product": 1669,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 589.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130613,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:15:58",
      "date_upd": "2014-04-01 12:16:17"
    },
    {
      "id_product": 1670,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 802.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130622,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:20:47",
      "date_upd": "2014-04-01 12:21:13"
    },
    {
      "id_product": 1671,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 800,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 131413,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:23:49",
      "date_upd": "2014-04-01 12:24:10"
    },
    {
      "id_product": 1672,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 563.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130624,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:27:43",
      "date_upd": "2014-04-01 12:28:04"
    },
    {
      "id_product": 1673,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1069.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130623,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:31:11",
      "date_upd": "2014-04-01 12:31:35"
    },
    {
      "id_product": 1674,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1537.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130260,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:34:10",
      "date_upd": "2014-04-01 12:48:36"
    },
    {
      "id_product": 1675,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 624,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130617,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:38:16",
      "date_upd": "2014-04-01 12:39:02"
    },
    {
      "id_product": 1676,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 624,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130619,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:42:06",
      "date_upd": "2014-04-01 12:42:54"
    },
    {
      "id_product": 1677,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1550.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-01",
      "reduction_to": "2014-04-01",
      "reference": 130668,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-01 12:45:42",
      "date_upd": "2014-04-01 12:46:08"
    },
    {
      "id_product": 1678,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1091.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130725,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 10:51:03",
      "date_upd": "2014-04-02 10:51:54"
    },
    {
      "id_product": 1679,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 802.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130612,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 10:55:00",
      "date_upd": "2014-04-02 10:55:25"
    },
    {
      "id_product": 1680,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 573.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130614,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 10:58:28",
      "date_upd": "2014-04-02 10:58:53"
    },
    {
      "id_product": 1681,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1050.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130615,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:02:49",
      "date_upd": "2014-04-02 11:03:13"
    },
    {
      "id_product": 1682,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 940,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130485,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:06:39",
      "date_upd": "2014-04-02 11:07:11"
    },
    {
      "id_product": 1683,
      "id_supplier": 0,
      "id_manufacturer": 77,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1127.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130489,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:09:29",
      "date_upd": "2014-04-02 11:10:45"
    },
    {
      "id_product": 1684,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 939.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130616,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:13:31",
      "date_upd": "2014-04-02 11:13:59"
    },
    {
      "id_product": 1685,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 913.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130598,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:17:26",
      "date_upd": "2014-04-02 11:17:56"
    },
    {
      "id_product": 1686,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 786.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130600,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:21:44",
      "date_upd": "2014-04-02 11:22:05"
    },
    {
      "id_product": 1687,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1812.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130633,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:24:59",
      "date_upd": "2014-04-02 11:25:30"
    },
    {
      "id_product": 1688,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1409.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130601,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:28:47",
      "date_upd": "2014-04-02 11:29:09"
    },
    {
      "id_product": 1689,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2062.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130663,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:31:53",
      "date_upd": "2014-04-02 11:32:15"
    },
    {
      "id_product": 1690,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1221.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130618,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:38:33",
      "date_upd": "2014-04-02 11:39:08"
    },
    {
      "id_product": 1691,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 130608,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:43:57",
      "date_upd": "2014-04-02 11:44:22"
    },
    {
      "id_product": 1692,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1305.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 131436,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:48:53",
      "date_upd": "2014-04-02 11:49:12"
    },
    {
      "id_product": 1693,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1439.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 131438,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:52:28",
      "date_upd": "2014-04-02 11:53:35"
    },
    {
      "id_product": 1694,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1974.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 131420,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:56:01",
      "date_upd": "2014-04-02 11:56:23"
    },
    {
      "id_product": 1695,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1732.4,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 131424,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 11:58:52",
      "date_upd": "2014-04-02 11:59:48"
    },
    {
      "id_product": 1696,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 21,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2205.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-02",
      "reduction_to": "2014-04-02",
      "reference": 131422,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-02 12:01:57",
      "date_upd": "2014-04-02 12:02:21"
    },
    {
      "id_product": 1697,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 200,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": "",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 08:06:10",
      "date_upd": "2015-04-24 20:35:33"
    },
    {
      "id_product": 1698,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 58.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130910,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:11:52",
      "date_upd": "2014-04-04 11:12:26"
    },
    {
      "id_product": 1699,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 47.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130912,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:17:47",
      "date_upd": "2014-04-04 11:18:10"
    },
    {
      "id_product": 1700,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2314,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130913,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:21:19",
      "date_upd": "2014-04-04 11:21:43"
    },
    {
      "id_product": 1701,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 768.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130914,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:23:48",
      "date_upd": "2014-04-04 11:24:16"
    },
    {
      "id_product": 1702,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1024.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130916,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:29:36",
      "date_upd": "2014-04-04 11:30:00"
    },
    {
      "id_product": 1703,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 2633,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130911,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:33:20",
      "date_upd": "2014-04-04 11:34:01"
    },
    {
      "id_product": 1704,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 123.8,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130917,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:37:33",
      "date_upd": "2014-04-04 11:38:08"
    },
    {
      "id_product": 1705,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 149.6,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130921,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:40:01",
      "date_upd": "2014-04-04 11:40:23"
    },
    {
      "id_product": 1706,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 165.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130925,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:42:06",
      "date_upd": "2014-04-04 11:42:30"
    },
    {
      "id_product": 1707,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 394.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130923,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:51:37",
      "date_upd": "2014-04-04 11:52:12"
    },
    {
      "id_product": 1708,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 272,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130927,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:53:57",
      "date_upd": "2014-04-04 11:54:16"
    },
    {
      "id_product": 1709,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 318.2,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130926,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:55:59",
      "date_upd": "2014-04-04 11:56:21"
    },
    {
      "id_product": 1710,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 549,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 131075,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 11:58:50",
      "date_upd": "2014-04-04 11:59:42"
    },
    {
      "id_product": 1711,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 590.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 131037,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:02:29",
      "date_upd": "2014-04-04 12:02:54"
    },
    {
      "id_product": 1712,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 55.5,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130918,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:05:33",
      "date_upd": "2014-04-04 12:05:54"
    },
    {
      "id_product": 1713,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 63.9,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130920,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:08:39",
      "date_upd": "2014-04-04 12:09:00"
    },
    {
      "id_product": 1714,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 128.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130922,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:10:24",
      "date_upd": "2014-04-04 12:10:46"
    },
    {
      "id_product": 1715,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 145.1,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 130924,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:12:31",
      "date_upd": "2014-04-04 12:12:55"
    },
    {
      "id_product": 1716,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 359.7,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": 131083,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:14:53",
      "date_upd": "2014-04-04 12:15:13"
    },
    {
      "id_product": 1717,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 34,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-04",
      "reduction_to": "2014-04-04",
      "reference": "007868",
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-04 12:18:50",
      "date_upd": "2015-09-09 12:48:57"
    },
    {
      "id_product": 1718,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 6600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 130816,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 10:47:31",
      "date_upd": "2014-04-15 15:12:28"
    },
    {
      "id_product": 1719,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 7056,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300508825,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 10:54:50",
      "date_upd": "2022-03-01 13:45:00"
    },
    {
      "id_product": 1720,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 9000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300508429,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:03:07",
      "date_upd": "2014-04-15 15:13:51"
    },
    {
      "id_product": 1721,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 9000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300317205,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:07:33",
      "date_upd": "2016-09-19 14:10:37"
    },
    {
      "id_product": 1722,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 12000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300367767,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:11:00",
      "date_upd": "2014-04-16 14:24:58"
    },
    {
      "id_product": 1723,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 13000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300388458,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:13:09",
      "date_upd": "2014-04-16 14:25:58"
    },
    {
      "id_product": 1724,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 8000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300508344,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:15:48",
      "date_upd": "2014-04-16 14:27:48"
    },
    {
      "id_product": 1725,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 8000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300508368,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:17:37",
      "date_upd": "2014-04-16 14:30:32"
    },
    {
      "id_product": 1726,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 12000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300508382,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:19:15",
      "date_upd": "2014-04-16 14:29:35"
    },
    {
      "id_product": 1727,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 8050,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300508788,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:22:01",
      "date_upd": "2022-03-03 13:19:21"
    },
    {
      "id_product": 1728,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 12000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300432175,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:24:18",
      "date_upd": "2014-04-15 15:20:12"
    },
    {
      "id_product": 1729,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 13000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300432199,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:26:13",
      "date_upd": "2014-04-15 15:22:27"
    },
    {
      "id_product": 1730,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 13000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300450872,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:27:58",
      "date_upd": "2014-04-15 15:23:05"
    },
    {
      "id_product": 1731,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 18000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4008321137722,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:30:21",
      "date_upd": "2014-04-15 15:23:45"
    },
    {
      "id_product": 1732,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 20000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4008321082640,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:32:30",
      "date_upd": "2014-04-15 15:24:56"
    },
    {
      "id_product": 1733,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 18000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4008321082633,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:34:11",
      "date_upd": "2014-04-15 15:25:21"
    },
    {
      "id_product": 1734,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 18000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4008321082510,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:35:54",
      "date_upd": "2014-04-15 15:26:19"
    },
    {
      "id_product": 1735,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 18000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4008321082626,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:37:52",
      "date_upd": "2014-04-15 15:26:48"
    },
    {
      "id_product": 1736,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 21000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4006584412990,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:39:42",
      "date_upd": "2014-04-15 15:27:27"
    },
    {
      "id_product": 1737,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300507118,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:45:33",
      "date_upd": "2014-04-15 15:28:14"
    },
    {
      "id_product": 1738,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300507132,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:47:57",
      "date_upd": "2014-04-15 15:29:15"
    },
    {
      "id_product": 1739,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300506692,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:50:05",
      "date_upd": "2014-04-15 15:29:42"
    },
    {
      "id_product": 1740,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300507095,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:51:40",
      "date_upd": "2014-04-15 15:30:08"
    },
    {
      "id_product": 1741,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 4000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300382128,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:53:12",
      "date_upd": "2014-04-15 15:30:38"
    },
    {
      "id_product": 1742,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 12500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300405957,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:55:44",
      "date_upd": "2014-04-15 15:31:44"
    },
    {
      "id_product": 1743,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 7000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300506791,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 11:58:37",
      "date_upd": "2014-04-15 15:32:42"
    },
    {
      "id_product": 1744,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 7000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300506715,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:00:46",
      "date_upd": "2014-04-15 15:33:54"
    },
    {
      "id_product": 1745,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 12000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300351599,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:03:05",
      "date_upd": "2014-04-15 15:35:35"
    },
    {
      "id_product": 1746,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 28000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300288857,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:05:10",
      "date_upd": "2014-04-15 15:36:10"
    },
    {
      "id_product": 1747,
      "id_supplier": 0,
      "id_manufacturer": 62,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 45000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 4050300967103,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:07:35",
      "date_upd": "2014-04-15 15:36:52"
    },
    {
      "id_product": 1748,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 14000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 130813,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:12:51",
      "date_upd": "2014-04-15 15:38:40"
    },
    {
      "id_product": 1749,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 14000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 130835,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:14:50",
      "date_upd": "2014-04-15 15:39:11"
    },
    {
      "id_product": 1750,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 1500,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 130803,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:16:38",
      "date_upd": "2014-04-15 15:39:56"
    },
    {
      "id_product": 1751,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 14000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 130877,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:20:05",
      "date_upd": "2014-04-15 15:41:50"
    },
    {
      "id_product": 1752,
      "id_supplier": 0,
      "id_manufacturer": 74,
      "id_tax": 3,
      "id_category_default": 18,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 15000,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2014-04-10",
      "reduction_to": "2014-04-10",
      "reference": 130891,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2014-04-10 12:24:12",
      "date_upd": "2014-04-15 15:42:56"
    },
    {
      "id_product": 1753,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 11,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 1,
      "price": 600,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2015-12-29",
      "reduction_to": "2015-12-29",
      "reference": 130419,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2015-12-29 15:37:35",
      "date_upd": "2016-08-19 14:48:38"
    },
    {
      "id_product": 1754,
      "id_supplier": 0,
      "id_manufacturer": 0,
      "id_tax": 3,
      "id_category_default": 13,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 0,
      "quantity": 3,
      "price": 990,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2019-08-19",
      "reduction_to": "2019-08-19",
      "reference": 890503,
      "supplier_reference": "",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2019-08-19 13:37:27",
      "date_upd": "2019-08-20 14:02:43"
    },
    {
      "id_product": 1755,
      "id_supplier": 0,
      "id_manufacturer": 63,
      "id_tax": 3,
      "id_category_default": 14,
      "id_color_default": 0,
      "on_sale": 0,
      "ean13": "",
      "ecotax": 2.5,
      "quantity": 75,
      "price": 450,
      "wholesale_price": 0,
      "reduction_price": 0,
      "reduction_percent": 0,
      "reduction_from": "2020-05-14",
      "reduction_to": "2020-05-14",
      "reference": "",
      "supplier_reference": "130934b",
      "location": "",
      "weight": 0,
      "out_of_stock": 2,
      "quantity_discount": 0,
      "customizable": 0,
      "uploadable_files": 0,
      "text_fields": 0,
      "active": 1,
      "indexed": 1,
      "date_add": "2020-05-07 13:51:42",
      "date_upd": "2020-05-07 13:55:06"
    }
];

module.exports = {products};