import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "./productDetail.css";
import { Navbar } from "./../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { ProductItemDetails } from "../../components/productItemDetails/ProductItemDetails";
import { ContactForm } from "../../components/contactForm/ContactForm";
import Footer from "../../components/footer/Footer";
import { products } from "../../merged_products";
export const ProductDetail = () => {
  //načtení id z URL
  const productId = useLocation().pathname.split("/")[2].toString();
  //nalezení produktu na základě id
  const product = products.filter(
    (p) => p.link_rewrite.toString() === productId
  )[0];
  console.log(product);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="productDetailWrapper parentDiv">
      <Helmet>
        <title>{product.name} | Gazar Group</title>
        <meta name="description" content={product.description} />
        <meta name="keywords" content={product.name} />
        <meta property="og:title" content={`${product.name} - GazarGroup`} />
        <meta property="og:description" content={product.description} />
        <meta
          property="og:image"
          content="https://gazargroup.com/logo192.png"
        />
        <meta
          property="og:url"
          content={`https://www.gazargroup.com/product/${product.link_rewrite}`}
        />
        <meta name="twitter:title" content={`${product.name} - Gazar Group`} />
        <meta name="twitter:description" content={product.description} />
        <meta
          name="twitter:image"
          content="https://gazargroup.com/logo192.png"
        />
      </Helmet>
      <div className="pageWrapper">
        <nav>
          <Navbar />
        </nav>
        <div className="bodyContent">
          <ProductItemDetails product={product} />
          <ContactForm isPopUp={true} />
          <Footer />
        </div>
      </div>
    </div>
  );
};
