import React from "react";
import { useState } from "react";
import MailIcon from "@mui/icons-material/Mail";
import Check from "@mui/icons-material/Check";
import ChatIcon from "@mui/icons-material/Chat";
import CancelIcon from "@mui/icons-material/Cancel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./contactForm.css";
import emailjs from "emailjs-com";

export const ContactForm = ({ isPopUp }) => {
  const [isContactFormOpen, setContactFormOpen] = useState(false);

  const toggleContact = () => {
    setContactFormOpen(!isContactFormOpen);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  const sendContactUsEmail = () => {
    const userCommentHTML = `  <table style="border-collapse: collapse; width: 800px; height: auto; margin: 20px 0; font-family: Arial, sans-serif;">
  <thead>
    <tr>
      <th style="background-color: #f75e4f; color: white; text-align: left; padding: 8px; border: 1px solid #ddd;">Váš komentář</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="text-align: left; padding: 8px; border: 1px solid #ddd;">${formData.message}</td>
    </tr>
  </tbody>
</table>`;


    const postData = {
      to_name: formData.name,
      to_email: formData.email,
      userComment: userCommentHTML,
    };
    emailjs
      .send(
        "service_s5nwy8c",
        "template_kd49xmy",
        postData,
        "wPds4JUSeJ59Rg_xF"
      )
      .then(
        (response) => {
          console.log("Email successfully sent!", response);
          alert("Váš podnět byl přijat.");
        },
        (err) => {
          console.error("Failed to send email. Error: ", err);
          alert("Nastal problém s odesláním emailu.");
        }
      );
  };

  return (
    <>
      <div className="chat-icon-wrapper" onClick={toggleContact}>
        <ChatIcon className="chat-icon"  />
      </div>
      {isContactFormOpen && (
        <div className="contact-form-container" data-aos="fade-up">
          <div className="contact-form">
            <div className="form-wrap-additional">
              <div className="contact-form-header">
                <span>Kontaktujte nás</span>
                <HighlightOffIcon
                  className="mui close-icon"
                  sx={{ width: "46px", height: "46px" }}
                  onClick={toggleContact}
                />
              </div>
              <form onSubmit={handleSubmit} className="contact-form-body">
                <div className="form-group">
                  <label>
                    Jméno
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Email
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <label className="form-group">
                  <textarea
                    name="message"
                    placeholder="Zadejte technické zadání vašeho požadavku. Jaký typ svítidla hledáte? Kolik kusů? Do jakého termínu?"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </label>
                <button
                  type="submit"
                  onClick={sendContactUsEmail}
                  className="submit-btn"
                >
                  <span className="submit-btn-text">Odeslat email</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
